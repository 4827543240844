import React from "react";
import { Input, Tooltip } from "antd";
import SkillImages from "./SkillImages";
import "../assets/css/HighscoreSkill.css";

const HighscoreSkill = React.memo(
  (props) => {
    const updatePlayerSkillLevel = (level) => {
      if (level === "" || !isNaN(level)) {
        props.setPlayerSkills((oldState) => {
          let skills = { ...oldState };
          skills[props.skill] = Number(level);
          return skills;
        });
      } else {
        console.log("Please enter a valid number");
      }
    };

    return (
      <div key={props.skill} className="hs-skill-container">
        <Tooltip title={props.skill} placement="bottom">
          <img
            key={props.skill + "-img"}
            className="hs-skill-tooltip"
            alt="skill"
            src={SkillImages[props.skill]}
          />
        </Tooltip>
        <Input
          key={props.skill + "-input"}
          size="small"
          className="hs-skill-input"
          value={props.playerSkills[props.skill]}
          onChange={(e) => {
            updatePlayerSkillLevel(e.target.value);
          }}
          onBlur={(e) => {
            if (e.target.value === "") {
              let level = props.getDefaultStartingSkillLevel(props.skill);
              updatePlayerSkillLevel(level);
            }
          }}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.playerSkills[prevProps.skill] ===
      nextProps.playerSkills[nextProps.skill]
    );
  }
);
export default HighscoreSkill;
