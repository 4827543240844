import React from "react";
import "./App.css";
import AppLayout from "./components/AppLayout";

import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <CookiesProvider>
      <AppLayout />
    </CookiesProvider>
  );
}

export default App;
