import React, { useEffect, useState } from "react";
import { Modal, Button, message, Popconfirm } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import Highscores from "./Highscores";
import QuestRequirements from "./QuestRequirements";
import MasterQuestList from "./MasterQuestList";
import QuestPlan from "./QuestPlan";
import { SkillsData, QuestsData } from "./Data";
import "../App.css";

const QuestPlanner = (props) => {
  const [quests, setQuests] = useState([]);
  const [skills, setSkills] = useState([]);
  const [questStatus, setQuestStatus] = useState({});
  const [playerSkills, setPlayerSkills] = useState({});
  const [apiDataHasLoaded, setApiDataHasLoaded] = useState(false);
  const [savePlanModalVisible, setSavePlanModalVisible] = useState(false);
  const cookieName = "osrs-quest-planner";
  const [questStatusCookie, setQuestStatusCookie] = useCookies([cookieName]);

  useEffect(() => {
    const mapQuestKeysForTable = (quests, parentKey) => {
      quests.forEach((q) => {
        q.key = parentKey + q.QuestId + "-";
        q.children = mapQuestKeysForTable(q.SubquestRequirements, q.key);
        if (q.SkillRequirements.length > 0) {
          q.children.unshift({
            RequirementsRow: true,
            SkillRequirements: q.SkillRequirements,
          });
        }
        q.children.unshift({
          InfoRow: true,
          Name: q.Name,
          Length: q.Length,
          Members: q.Members,
          QuestPoints: q.QuestPoints,
          SkillRequirements: q.SkillRequirements,
        });
      });
      return quests;
    };

    const timer = setTimeout(() => {
      let questData = mapQuestKeysForTable(QuestsData, "0-");
      questData = questData.sort((a, b) => (a.Name > b.Name ? 1 : -1));
      setQuests(questData);
      setSkills(SkillsData);
      if (Object.keys(questStatusCookie).length > 0) {
        // saved quest plan found in cookies
        let status = questStatusCookie[cookieName];
        setQuestStatus(status);
      }
      setApiDataHasLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const openModal = () => {
    setSavePlanModalVisible(true);
  };
  const closeModal = () => {
    setSavePlanModalVisible(false);
  };
  const saveQuestStatusCookies = () => {
    setQuestStatusCookie(cookieName, questStatus, { path: "/", sameSite: true });
    closeModal();
    message.success({
      content: "Plan Saved!",
      style: {
        marginLeft: "200px",
      },
      key: "hs-notification",
      duration: 2,
    });
  };

  const clearQuestStatusCookies = () => {
    setQuestStatusCookie(cookieName, {}, { path: "/", sameSite: true });
    setQuestStatus({});
    closeModal();
    message.success({
      content: "Plan Cleared!",
      style: {
        marginLeft: "200px",
      },
      key: "hs-notification",
      duration: 2,
    });
  };
  return (
    <>
      <div className="flex-container" style={{ display: "flex" }}>
        <Button
          type="primary"
          onClick={openModal}
          icon={<SaveOutlined />}
          style={{ position: "absolute", right: "15px", top: "8px" }}
        >
          Save Plan
        </Button>
        <Modal
          visible={savePlanModalVisible}
          title="Save Plan"
          onCancel={closeModal}
          footer={[
            <Popconfirm
              title="Delete your current quest plan and cookies?"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              okButtonProps={{ danger: true, type: "default" }}
              onConfirm={clearQuestStatusCookies}
              okText="Clear"
              cancelText="Cancel"
            >
              <Button type="link" style={{ float: "left" }} danger>
                Clear
              </Button>
            </Popconfirm>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={saveQuestStatusCookies}>
              Save
            </Button>,
          ]}
        >
          <h5>By clicking save, you agree to save information in your browser cookies:</h5>
          <br />
          <ul>
            <li>We only stored required information for this application to work.</li>
            <li>Information we save stays in your browser, we do not collect it.</li>
            <li>If you clear your browser cookies, your quest plan will be removed.</li>
          </ul>
        </Modal>
        <div className="highscores">
          <Highscores
            skills={skills}
            playerSkills={playerSkills}
            setPlayerSkills={setPlayerSkills}
            apiDataHasLoaded={apiDataHasLoaded}
          />
        </div>
        <div style={{ flex: "1", textAlign: "center" }}>
          <QuestRequirements
            skills={skills}
            quests={quests}
            questStatus={questStatus}
            playerSkills={playerSkills}
          />
        </div>
      </div>
      <div className="table-flex-container">
        <MasterQuestList
          quests={quests}
          questStatus={questStatus}
          setQuestStatus={setQuestStatus}
          playerSkills={playerSkills}
        ></MasterQuestList>
        <QuestPlan
          quests={quests}
          skills={skills}
          questStatus={questStatus}
          setQuestStatus={setQuestStatus}
          playerSkills={playerSkills}
        ></QuestPlan>
      </div>
    </>
  );
};

export default QuestPlanner;
