import React from "react";
import { Input } from "antd";

const QuestFilter = (props) => {
  return (
    <Input
      style={{ marginBottom: 8 }}
      placeholder="Search"
      onChange={(e) => props.setSearch(e.target.value)}
    />
  );
};

export default QuestFilter;
