export function filterQuestListByStatus(quests, statuses, statusToFilterBy) {
  let questIds = Object.keys(statuses)
    .filter((questId) => statuses[questId] === statusToFilterBy)
    .map(Number);
  let x = quests.filter((x) => questIds.includes(x.QuestId));
  return x;
}

export const QuestStatus = Object.freeze({
  NotStarted: 1,
  Planned: 2,
  Completed: 3,
});

export function sortSkillsByDisplayOrder(skills) {
  return skills.sort((a, b) => a.SortOrder - b.SortOrder).map((a) => a.Name);
}

export function getQuestPointsFromCompletedQuests(quests, questStatus) {
  let playerQuestPoints = 0;
  let completedQuests = filterQuestListByStatus(quests, questStatus, QuestStatus.Completed);
  completedQuests.forEach((quest) => {
    playerQuestPoints += Number(quest.QuestPoints);
  });
  return playerQuestPoints;
}

export function getChildQuestIds(quest, arr = []) {
  quest.SubquestRequirements.forEach((q) => {
    if (q.InfoRow !== true && q.RequirementsRow !== true) {
      arr.push(q.QuestId);
      getChildQuestIds(q, arr);
    }
  });
  return arr;
}

export function getChildQuestKeys(quest, arr = []) {
  quest.SubquestRequirements.forEach((q) => {
    if (q.InfoRow !== true && q.RequirementsRow !== true) {
      arr.push(q.key);
      getChildQuestKeys(q, arr);
    }
  });
  return arr;
}
