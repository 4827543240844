export const SkillsData = [
  { Id: 2, Name: "Attack", SortOrder: 1 },
  { Id: 6, Name: "Defence", SortOrder: 2 },
  { Id: 21, Name: "Strength", SortOrder: 3 },
  { Id: 12, Name: "Hitpoints", SortOrder: 4 },
  { Id: 17, Name: "Ranged", SortOrder: 5 },
  { Id: 16, Name: "Prayer", SortOrder: 6 },
  { Id: 14, Name: "Magic", SortOrder: 7 },
  { Id: 4, Name: "Cooking", SortOrder: 8 },
  { Id: 23, Name: "Woodcutting", SortOrder: 9 },
  { Id: 10, Name: "Fletching", SortOrder: 10 },
  { Id: 9, Name: "Fishing", SortOrder: 11 },
  { Id: 8, Name: "Firemaking", SortOrder: 12 },
  { Id: 5, Name: "Crafting", SortOrder: 13 },
  { Id: 20, Name: "Smithing", SortOrder: 14 },
  { Id: 15, Name: "Mining", SortOrder: 15 },
  { Id: 11, Name: "Herblore", SortOrder: 16 },
  { Id: 1, Name: "Agility", SortOrder: 17 },
  { Id: 22, Name: "Thieving", SortOrder: 18 },
  { Id: 19, Name: "Slayer", SortOrder: 19 },
  { Id: 7, Name: "Farming", SortOrder: 20 },
  { Id: 18, Name: "Runecrafting", SortOrder: 21 },
  { Id: 13, Name: "Hunter", SortOrder: 22 },
  { Id: 3, Name: "Construction", SortOrder: 23 },
  { Id: 26, Name: "Hosidius Favour", SortOrder: 24 },
  { Id: 27, Name: "Port Piscarilius Favour", SortOrder: 25 },
  { Id: 28, Name: "Lovakengj Favour", SortOrder: 26 },
  { Id: 24, Name: "Combat Level", SortOrder: 27 },
  { Id: 25, Name: "Quest Points", SortOrder: 28 },
];

export const QuestsData = [
  {
    QuestId: 1,
    Name: "A Soul\u0027s Bane",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 2,
    Name: "A Tail of Two Cats",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 53,
        Name: "Icthlarin\u0027s Little Helper",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 44,
            Name: "Gertrude\u0027s Cat",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 3,
    Name: "A Taste of Hope",
    Length: "Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 3, SkillId: 5, SkillName: "Crafting", Level: 48, Boostable: false },
      { QuestId: 3, SkillId: 2, SkillName: "Attack", Level: 40, Boostable: false },
      { QuestId: 3, SkillId: 19, SkillName: "Slayer", Level: 38, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 18,
        Name: "Darkness of Hallowvale",
        Length: "Very long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 18,
            SkillId: 21,
            SkillName: "Strength",
            Level: 40,
            Boostable: false,
          },
          { QuestId: 18, SkillId: 14, SkillName: "Magic", Level: 33, Boostable: false },
          { QuestId: 18, SkillId: 5, SkillName: "Crafting", Level: 32, Boostable: false },
          { QuestId: 18, SkillId: 1, SkillName: "Agility", Level: 26, Boostable: false },
          {
            QuestId: 18,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 22,
            Boostable: false,
          },
          { QuestId: 18, SkillId: 15, SkillName: "Mining", Level: 20, Boostable: false },
          {
            QuestId: 18,
            SkillId: 3,
            SkillName: "Construction",
            Level: 5,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 55,
            Name: "In Aid of the Myreque",
            Length: "Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 55,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 25,
                Boostable: false,
              },
              {
                QuestId: 55,
                SkillId: 15,
                SkillName: "Mining",
                Level: 15,
                Boostable: false,
              },
              { QuestId: 55, SkillId: 14, SkillName: "Magic", Level: 7, Boostable: false },
            ],
            SubquestRequirements: [
              {
                QuestId: 56,
                Name: "In Search of the Myreque",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 56,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 25,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 74,
                    Name: "Nature Spirit",
                    Length: "Medium",
                    QuestPoints: 2,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [
                      {
                        QuestId: 80,
                        Name: "Priest in Peril",
                        Length: "Medium",
                        QuestPoints: 1,
                        Members: "P2P",
                        SkillRequirements: [],
                        SubquestRequirements: [],
                      },
                      {
                        QuestId: 136,
                        Name: "The Restless Ghost",
                        Length: "Short",
                        QuestPoints: 1,
                        Members: "F2P",
                        SkillRequirements: [],
                        SubquestRequirements: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 4,
    Name: "Animal Magnetism",
    Length: "Medium \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 4, SkillId: 23, SkillName: "Woodcutting", Level: 35, Boostable: false },
      { QuestId: 4, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
      { QuestId: 4, SkillId: 5, SkillName: "Crafting", Level: 19, Boostable: false },
      { QuestId: 4, SkillId: 19, SkillName: "Slayer", Level: 18, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 36,
        Name: "Ernest the Chicken",
        Length: "Short",
        QuestPoints: 4,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 136,
        Name: "The Restless Ghost",
        Length: "Short",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 5,
    Name: "Another Slice of H.A.M.",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 5, SkillId: 16, SkillName: "Prayer", Level: 25, Boostable: false },
      { QuestId: 5, SkillId: 2, SkillName: "Attack", Level: 15, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 20,
        Name: "Death to the Dorgeshuun",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 20, SkillId: 1, SkillName: "Agility", Level: 23, Boostable: true },
          { QuestId: 20, SkillId: 22, SkillName: "Thieving", Level: 23, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 134,
            Name: "The Lost Tribe",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 134,
                SkillId: 15,
                SkillName: "Mining",
                Level: 17,
                Boostable: false,
              },
              {
                QuestId: 134,
                SkillId: 1,
                SkillName: "Agility",
                Level: 13,
                Boostable: false,
              },
              {
                QuestId: 134,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 13,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 46,
                Name: "Goblin Diplomacy",
                Length: "Short",
                QuestPoints: 5,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 102,
                Name: "Rune Mysteries",
                Length: "Short (\u003C5 minutes)",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 121,
        Name: "The Dig Site",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 121,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 25,
            Boostable: false,
          },
          { QuestId: 121, SkillId: 1, SkillName: "Agility", Level: 10, Boostable: false },
          {
            QuestId: 121,
            SkillId: 11,
            SkillName: "Herblore",
            Level: 10,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 128,
        Name: "The Giant Dwarf",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 128, SkillId: 14, SkillName: "Magic", Level: 33, Boostable: false },
          {
            QuestId: 128,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 16,
            Boostable: false,
          },
          {
            QuestId: 128,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 14,
            Boostable: false,
          },
          { QuestId: 128, SkillId: 5, SkillName: "Crafting", Level: 12, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 6,
    Name: "Between a Rock...",
    Length: "Medium \u2013 Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 6, SkillId: 20, SkillName: "Smithing", Level: 50, Boostable: true },
      { QuestId: 6, SkillId: 15, SkillName: "Mining", Level: 40, Boostable: true },
      { QuestId: 6, SkillId: 6, SkillName: "Defence", Level: 30, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 29,
        Name: "Dwarf Cannon",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 41,
        Name: "Fishing Contest",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 41, SkillId: 9, SkillName: "Fishing", Level: 10, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 7,
    Name: "Big Chompy Bird Hunting",
    Length: "Short",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 7, SkillId: 4, SkillName: "Cooking", Level: 30, Boostable: false },
      { QuestId: 7, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
      { QuestId: 7, SkillId: 10, SkillName: "Fletching", Level: 5, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 8,
    Name: "Biohazard",
    Length: "Medium",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 79,
        Name: "Plague City",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 9,
    Name: "Black Knights\u0027 Fortress",
    Length: "Short",
    QuestPoints: 3,
    Members: "F2P",
    SkillRequirements: [
      { QuestId: 9, SkillId: 25, SkillName: "Quest Points", Level: 12, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 10,
    Name: "Bone Voyage",
    Length: "Short \u2013 Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 121,
        Name: "The Dig Site",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 121,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 25,
            Boostable: false,
          },
          { QuestId: 121, SkillId: 1, SkillName: "Agility", Level: 10, Boostable: false },
          {
            QuestId: 121,
            SkillId: 11,
            SkillName: "Herblore",
            Level: 10,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 11,
    Name: "Cabin Fever",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 11, SkillId: 20, SkillName: "Smithing", Level: 50, Boostable: false },
      { QuestId: 11, SkillId: 5, SkillName: "Crafting", Level: 45, Boostable: false },
      { QuestId: 11, SkillId: 1, SkillName: "Agility", Level: 42, Boostable: false },
      { QuestId: 11, SkillId: 17, SkillName: "Ranged", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 78,
        Name: "Pirate\u0027s Treasure",
        Length: "Short",
        QuestPoints: 2,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 101,
        Name: "Rum Deal",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 101, SkillId: 9, SkillName: "Fishing", Level: 50, Boostable: true },
          { QuestId: 101, SkillId: 16, SkillName: "Prayer", Level: 47, Boostable: true },
          {
            QuestId: 101,
            SkillId: 5,
            SkillName: "Crafting",
            Level: 42,
            Boostable: false,
          },
          { QuestId: 101, SkillId: 19, SkillName: "Slayer", Level: 42, Boostable: false },
          { QuestId: 101, SkillId: 7, SkillName: "Farming", Level: 40, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 154,
            Name: "Zogre Flesh Eaters",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 154,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 154,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 154,
                SkillId: 21,
                SkillName: "Strength",
                Level: 20,
                Boostable: false,
              },
              {
                QuestId: 154,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 8,
                Boostable: false,
              },
              {
                QuestId: 154,
                SkillId: 20,
                SkillName: "Smithing",
                Level: 4,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 7,
                Name: "Big Chompy Bird Hunting",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 7,
                    SkillId: 4,
                    SkillName: "Cooking",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 10,
                    SkillName: "Fletching",
                    Level: 5,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 12,
    Name: "Client of Kourend",
    Length: "Medium (15-20 minutes)",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 13,
    Name: "Clock Tower",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 14,
    Name: "Cold War",
    Length: "Medium \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 14, SkillId: 3, SkillName: "Construction", Level: 34, Boostable: true },
      { QuestId: 14, SkillId: 1, SkillName: "Agility", Level: 30, Boostable: false },
      { QuestId: 14, SkillId: 5, SkillName: "Crafting", Level: 30, Boostable: false },
      { QuestId: 14, SkillId: 22, SkillName: "Thieving", Level: 15, Boostable: false },
      { QuestId: 14, SkillId: 13, SkillName: "Hunter", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 15,
    Name: "Contact!",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 53,
        Name: "Icthlarin\u0027s Little Helper",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 44,
            Name: "Gertrude\u0027s Cat",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 81,
        Name: "Prince Ali Rescue",
        Length: "Short",
        QuestPoints: 3,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 16,
    Name: "Cook\u0027s Assistant",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 17,
    Name: "Creature of Fenkenstrain",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 17, SkillId: 22, SkillName: "Thieving", Level: 25, Boostable: false },
      { QuestId: 17, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 136,
        Name: "The Restless Ghost",
        Length: "Short",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 18,
    Name: "Darkness of Hallowvale",
    Length: "Very long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 18, SkillId: 21, SkillName: "Strength", Level: 40, Boostable: false },
      { QuestId: 18, SkillId: 14, SkillName: "Magic", Level: 33, Boostable: false },
      { QuestId: 18, SkillId: 5, SkillName: "Crafting", Level: 32, Boostable: false },
      { QuestId: 18, SkillId: 1, SkillName: "Agility", Level: 26, Boostable: false },
      { QuestId: 18, SkillId: 22, SkillName: "Thieving", Level: 22, Boostable: false },
      { QuestId: 18, SkillId: 15, SkillName: "Mining", Level: 20, Boostable: false },
      { QuestId: 18, SkillId: 3, SkillName: "Construction", Level: 5, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 55,
        Name: "In Aid of the Myreque",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 55, SkillId: 5, SkillName: "Crafting", Level: 25, Boostable: false },
          { QuestId: 55, SkillId: 15, SkillName: "Mining", Level: 15, Boostable: false },
          { QuestId: 55, SkillId: 14, SkillName: "Magic", Level: 7, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 56,
            Name: "In Search of the Myreque",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 56,
                SkillId: 1,
                SkillName: "Agility",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 74,
                Name: "Nature Spirit",
                Length: "Medium",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 80,
                    Name: "Priest in Peril",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 136,
                    Name: "The Restless Ghost",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "F2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 19,
    Name: "Death Plateau",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 20,
    Name: "Death to the Dorgeshuun",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 20, SkillId: 1, SkillName: "Agility", Level: 23, Boostable: true },
      { QuestId: 20, SkillId: 22, SkillName: "Thieving", Level: 23, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 134,
        Name: "The Lost Tribe",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 134, SkillId: 15, SkillName: "Mining", Level: 17, Boostable: false },
          { QuestId: 134, SkillId: 1, SkillName: "Agility", Level: 13, Boostable: false },
          {
            QuestId: 134,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 13,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 46,
            Name: "Goblin Diplomacy",
            Length: "Short",
            QuestPoints: 5,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 21,
    Name: "Demon Slayer",
    Length: "Medium",
    QuestPoints: 3,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 22,
    Name: "Desert Treasure",
    Length: "Very long",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 22, SkillId: 22, SkillName: "Thieving", Level: 53, Boostable: false },
      { QuestId: 22, SkillId: 8, SkillName: "Firemaking", Level: 50, Boostable: false },
      { QuestId: 22, SkillId: 14, SkillName: "Magic", Level: 50, Boostable: false },
      { QuestId: 22, SkillId: 19, SkillName: "Slayer", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 117,
        Name: "Temple of Ikov",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 117,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 42,
            Boostable: false,
          },
          { QuestId: 117, SkillId: 17, SkillName: "Ranged", Level: 40, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 121,
        Name: "The Dig Site",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 121,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 25,
            Boostable: false,
          },
          { QuestId: 121, SkillId: 1, SkillName: "Agility", Level: 10, Boostable: false },
          {
            QuestId: 121,
            SkillId: 11,
            SkillName: "Herblore",
            Level: 10,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 138,
        Name: "The Tourist Trap",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 138,
            SkillId: 20,
            SkillName: "Smithing",
            Level: 20,
            Boostable: false,
          },
          {
            QuestId: 138,
            SkillId: 10,
            SkillName: "Fletching",
            Level: 10,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 144,
        Name: "Troll Stronghold",
        Length: "Short \u2013 Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 144, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 19,
            Name: "Death Plateau",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 149,
        Name: "Waterfall Quest",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 23,
    Name: "Devious Minds",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 23, SkillId: 20, SkillName: "Smithing", Level: 65, Boostable: true },
      { QuestId: 23, SkillId: 10, SkillName: "Fletching", Level: 50, Boostable: false },
      { QuestId: 23, SkillId: 18, SkillName: "Runecrafting", Level: 50, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 24,
        Name: "Doric\u0027s Quest",
        Length: "Short",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 155,
        Name: "Enter the Abyss",
        Length: "Short",
        QuestPoints: 0,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 144,
        Name: "Troll Stronghold",
        Length: "Short \u2013 Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 144, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 19,
            Name: "Death Plateau",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 147,
        Name: "Wanted!",
        Length: "Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 147,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 32,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 155,
            Name: "Enter the Abyss",
            Length: "Short",
            QuestPoints: 0,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 102,
                Name: "Rune Mysteries",
                Length: "Short (\u003C5 minutes)",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 96,
            Name: "Recruitment Drive",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 9,
                Name: "Black Knights\u0027 Fortress",
                Length: "Short",
                QuestPoints: 3,
                Members: "F2P",
                SkillRequirements: [
                  {
                    QuestId: 9,
                    SkillId: 25,
                    SkillName: "Quest Points",
                    Level: 12,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 28,
                Name: "Druidic Ritual",
                Length: "Short",
                QuestPoints: 4,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 134,
            Name: "The Lost Tribe",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 134,
                SkillId: 15,
                SkillName: "Mining",
                Level: 17,
                Boostable: false,
              },
              {
                QuestId: 134,
                SkillId: 1,
                SkillName: "Agility",
                Level: 13,
                Boostable: false,
              },
              {
                QuestId: 134,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 13,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 46,
                Name: "Goblin Diplomacy",
                Length: "Short",
                QuestPoints: 5,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 102,
                Name: "Rune Mysteries",
                Length: "Short (\u003C5 minutes)",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 24,
    Name: "Doric\u0027s Quest",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 25,
    Name: "Dragon Slayer",
    Length: "Long",
    QuestPoints: 2,
    Members: "F2P",
    SkillRequirements: [
      { QuestId: 25, SkillId: 25, SkillName: "Quest Points", Level: 32, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 26,
    Name: "Dragon Slayer II",
    Length: "Very long",
    QuestPoints: 5,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 26,
        SkillId: 25,
        SkillName: "Quest Points",
        Level: 200,
        Boostable: false,
      },
      { QuestId: 26, SkillId: 14, SkillName: "Magic", Level: 75, Boostable: false },
      { QuestId: 26, SkillId: 20, SkillName: "Smithing", Level: 70, Boostable: false },
      { QuestId: 26, SkillId: 15, SkillName: "Mining", Level: 68, Boostable: false },
      { QuestId: 26, SkillId: 5, SkillName: "Crafting", Level: 62, Boostable: false },
      { QuestId: 26, SkillId: 1, SkillName: "Agility", Level: 60, Boostable: false },
      { QuestId: 26, SkillId: 22, SkillName: "Thieving", Level: 60, Boostable: false },
      { QuestId: 26, SkillId: 3, SkillName: "Construction", Level: 50, Boostable: false },
      { QuestId: 26, SkillId: 12, SkillName: "Hitpoints", Level: 50, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 2,
        Name: "A Tail of Two Cats",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 53,
            Name: "Icthlarin\u0027s Little Helper",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 44,
                Name: "Gertrude\u0027s Cat",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 4,
        Name: "Animal Magnetism",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 4,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 35,
            Boostable: false,
          },
          { QuestId: 4, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
          { QuestId: 4, SkillId: 5, SkillName: "Crafting", Level: 19, Boostable: false },
          { QuestId: 4, SkillId: 19, SkillName: "Slayer", Level: 18, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 36,
            Name: "Ernest the Chicken",
            Length: "Short",
            QuestPoints: 4,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 10,
        Name: "Bone Voyage",
        Length: "Short \u2013 Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 121,
            Name: "The Dig Site",
            Length: "Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 121,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 25,
                Boostable: false,
              },
              {
                QuestId: 121,
                SkillId: 1,
                SkillName: "Agility",
                Level: 10,
                Boostable: false,
              },
              {
                QuestId: 121,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 10,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 12,
        Name: "Client of Kourend",
        Length: "Medium (15-20 minutes)",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 27,
        Name: "Dream Mentor",
        Length: "Short \u2013 Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 27,
            SkillId: 24,
            SkillName: "Combat Level",
            Level: 85,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 30,
            Name: "Eadgar\u0027s Ruse",
            Length: "Medium \u2013 Long",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 30,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 31,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 28,
                Name: "Druidic Ritual",
                Length: "Short",
                QuestPoints: 4,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 144,
                Name: "Troll Stronghold",
                Length: "Short \u2013 Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 144,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 15,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 19,
                    Name: "Death Plateau",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 61,
            Name: "Lunar Diplomacy",
            Length: "Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 61,
                SkillId: 14,
                SkillName: "Magic",
                Level: 65,
                Boostable: false,
              },
              {
                QuestId: 61,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 61,
                Boostable: false,
              },
              {
                QuestId: 61,
                SkillId: 15,
                SkillName: "Mining",
                Level: 60,
                Boostable: false,
              },
              {
                QuestId: 61,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 55,
                Boostable: false,
              },
              {
                QuestId: 61,
                SkillId: 8,
                SkillName: "Firemaking",
                Level: 49,
                Boostable: false,
              },
              {
                QuestId: 61,
                SkillId: 6,
                SkillName: "Defence",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 61,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 60,
                Name: "Lost City",
                Length: "Short",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 60,
                    SkillId: 23,
                    SkillName: "Woodcutting",
                    Level: 36,
                    Boostable: false,
                  },
                  {
                    QuestId: 60,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 31,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 102,
                Name: "Rune Mysteries",
                Length: "Short (\u003C5 minutes)",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 110,
                Name: "Shilo Village",
                Length: "Medium \u2013 Long",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 110,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 32,
                    Boostable: true,
                  },
                  {
                    QuestId: 110,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 20,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 57,
                    Name: "Jungle Potion",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 57,
                        SkillId: 11,
                        SkillName: "Herblore",
                        Level: 3,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                ],
              },
              {
                QuestId: 127,
                Name: "The Fremennik Trials",
                Length: "Long",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 127,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 40,
                    Boostable: true,
                  },
                  {
                    QuestId: 127,
                    SkillId: 23,
                    SkillName: "Woodcutting",
                    Level: 40,
                    Boostable: false,
                  },
                  {
                    QuestId: 127,
                    SkillId: 10,
                    SkillName: "Fletching",
                    Level: 25,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 45,
        Name: "Ghosts Ahoy",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 45, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
          { QuestId: 45, SkillId: 4, SkillName: "Cooking", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 59,
        Name: "Legends\u0027 Quest",
        Length: "Very long",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 59,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 107,
            Boostable: false,
          },
          { QuestId: 59, SkillId: 14, SkillName: "Magic", Level: 56, Boostable: false },
          { QuestId: 59, SkillId: 15, SkillName: "Mining", Level: 52, Boostable: true },
          { QuestId: 59, SkillId: 1, SkillName: "Agility", Level: 50, Boostable: true },
          { QuestId: 59, SkillId: 5, SkillName: "Crafting", Level: 50, Boostable: false },
          {
            QuestId: 59,
            SkillId: 20,
            SkillName: "Smithing",
            Level: 50,
            Boostable: false,
          },
          {
            QuestId: 59,
            SkillId: 21,
            SkillName: "Strength",
            Level: 50,
            Boostable: false,
          },
          {
            QuestId: 59,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 50,
            Boostable: false,
          },
          {
            QuestId: 59,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 50,
            Boostable: false,
          },
          {
            QuestId: 59,
            SkillId: 11,
            SkillName: "Herblore",
            Level: 45,
            Boostable: false,
          },
          { QuestId: 59, SkillId: 16, SkillName: "Prayer", Level: 42, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 39,
            Name: "Family Crest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 39,
                SkillId: 14,
                SkillName: "Magic",
                Level: 59,
                Boostable: true,
              },
              {
                QuestId: 39,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 39,
                SkillId: 15,
                SkillName: "Mining",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 39,
                SkillId: 20,
                SkillName: "Smithing",
                Level: 40,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 50,
            Name: "Heroes\u0027 Quest",
            Length: "Medium \u2013 Long",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 50,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 55,
                Boostable: false,
              },
              {
                QuestId: 50,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 9,
                SkillName: "Fishing",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 15,
                SkillName: "Mining",
                Level: 50,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 25,
                Name: "Dragon Slayer",
                Length: "Long",
                QuestPoints: 2,
                Members: "F2P",
                SkillRequirements: [
                  {
                    QuestId: 25,
                    SkillId: 25,
                    SkillName: "Quest Points",
                    Level: 32,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 60,
                Name: "Lost City",
                Length: "Short",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 60,
                    SkillId: 23,
                    SkillName: "Woodcutting",
                    Level: 36,
                    Boostable: false,
                  },
                  {
                    QuestId: 60,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 31,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 64,
                Name: "Merlin\u0027s Crystal",
                Length: "Medium",
                QuestPoints: 6,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 109,
                Name: "Shield of Arrav",
                Length: "Medium",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 110,
            Name: "Shilo Village",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 110,
                SkillId: 1,
                SkillName: "Agility",
                Level: 32,
                Boostable: true,
              },
              {
                QuestId: 110,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 145,
            Name: "Underground Pass",
            Length: "Long",
            QuestPoints: 5,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 145,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 8,
                Name: "Biohazard",
                Length: "Medium",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 79,
                    Name: "Plague City",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 149,
            Name: "Waterfall Quest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 27,
    Name: "Dream Mentor",
    Length: "Short \u2013 Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 27, SkillId: 24, SkillName: "Combat Level", Level: 85, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 30,
        Name: "Eadgar\u0027s Ruse",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 30, SkillId: 11, SkillName: "Herblore", Level: 31, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 28,
            Name: "Druidic Ritual",
            Length: "Short",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 144,
            Name: "Troll Stronghold",
            Length: "Short \u2013 Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 144,
                SkillId: 1,
                SkillName: "Agility",
                Level: 15,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 19,
                Name: "Death Plateau",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 61,
        Name: "Lunar Diplomacy",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 61, SkillId: 14, SkillName: "Magic", Level: 65, Boostable: false },
          { QuestId: 61, SkillId: 5, SkillName: "Crafting", Level: 61, Boostable: false },
          { QuestId: 61, SkillId: 15, SkillName: "Mining", Level: 60, Boostable: false },
          {
            QuestId: 61,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 55,
            Boostable: false,
          },
          {
            QuestId: 61,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 49,
            Boostable: false,
          },
          { QuestId: 61, SkillId: 6, SkillName: "Defence", Level: 40, Boostable: false },
          { QuestId: 61, SkillId: 11, SkillName: "Herblore", Level: 5, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 60,
            Name: "Lost City",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 60,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 36,
                Boostable: false,
              },
              {
                QuestId: 60,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 31,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 110,
            Name: "Shilo Village",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 110,
                SkillId: 1,
                SkillName: "Agility",
                Level: 32,
                Boostable: true,
              },
              {
                QuestId: 110,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 127,
            Name: "The Fremennik Trials",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 127,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 40,
                Boostable: true,
              },
              {
                QuestId: 127,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 127,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 28,
    Name: "Druidic Ritual",
    Length: "Short",
    QuestPoints: 4,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 29,
    Name: "Dwarf Cannon",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 30,
    Name: "Eadgar\u0027s Ruse",
    Length: "Medium \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 30, SkillId: 11, SkillName: "Herblore", Level: 31, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 28,
        Name: "Druidic Ritual",
        Length: "Short",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 144,
        Name: "Troll Stronghold",
        Length: "Short \u2013 Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 144, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 19,
            Name: "Death Plateau",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 31,
    Name: "Eagles\u0027 Peak",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 31, SkillId: 13, SkillName: "Hunter", Level: 27, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 32,
    Name: "Elemental Workshop I",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 32, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
      { QuestId: 32, SkillId: 15, SkillName: "Mining", Level: 20, Boostable: false },
      { QuestId: 32, SkillId: 20, SkillName: "Smithing", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 33,
    Name: "Elemental Workshop II",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 33, SkillId: 20, SkillName: "Smithing", Level: 30, Boostable: false },
      { QuestId: 33, SkillId: 14, SkillName: "Magic", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 32,
        Name: "Elemental Workshop I",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 32, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
          { QuestId: 32, SkillId: 15, SkillName: "Mining", Level: 20, Boostable: false },
          { QuestId: 32, SkillId: 20, SkillName: "Smithing", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 34,
    Name: "Enakhra\u0027s Lament",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 34, SkillId: 5, SkillName: "Crafting", Level: 50, Boostable: false },
      { QuestId: 34, SkillId: 8, SkillName: "Firemaking", Level: 45, Boostable: false },
      { QuestId: 34, SkillId: 16, SkillName: "Prayer", Level: 43, Boostable: false },
      { QuestId: 34, SkillId: 14, SkillName: "Magic", Level: 39, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 35,
    Name: "Enlightened Journey",
    Length: "Short \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 35, SkillId: 5, SkillName: "Crafting", Level: 36, Boostable: false },
      { QuestId: 35, SkillId: 7, SkillName: "Farming", Level: 30, Boostable: false },
      { QuestId: 35, SkillId: 8, SkillName: "Firemaking", Level: 20, Boostable: false },
      { QuestId: 35, SkillId: 25, SkillName: "Quest Points", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 36,
    Name: "Ernest the Chicken",
    Length: "Short",
    QuestPoints: 4,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 37,
    Name: "Fairytale I - Growing Pains",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 57,
        Name: "Jungle Potion",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 57, SkillId: 11, SkillName: "Herblore", Level: 3, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 60,
        Name: "Lost City",
        Length: "Short",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 60,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 36,
            Boostable: false,
          },
          { QuestId: 60, SkillId: 5, SkillName: "Crafting", Level: 31, Boostable: true },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 74,
        Name: "Nature Spirit",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 38,
    Name: "Fairytale II - Cure a Queen",
    Length: "Short \u2013 Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 38, SkillId: 11, SkillName: "Herblore", Level: 57, Boostable: true },
      { QuestId: 38, SkillId: 7, SkillName: "Farming", Level: 49, Boostable: true },
      { QuestId: 38, SkillId: 22, SkillName: "Thieving", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 37,
        Name: "Fairytale I - Growing Pains",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 60,
            Name: "Lost City",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 60,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 36,
                Boostable: false,
              },
              {
                QuestId: 60,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 31,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 74,
            Name: "Nature Spirit",
            Length: "Medium",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 136,
                Name: "The Restless Ghost",
                Length: "Short",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 39,
    Name: "Family Crest",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 39, SkillId: 14, SkillName: "Magic", Level: 59, Boostable: true },
      { QuestId: 39, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: false },
      { QuestId: 39, SkillId: 15, SkillName: "Mining", Level: 40, Boostable: false },
      { QuestId: 39, SkillId: 20, SkillName: "Smithing", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 40,
    Name: "Fight Arena",
    Length: "Short",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 41,
    Name: "Fishing Contest",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 41, SkillId: 9, SkillName: "Fishing", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 42,
    Name: "Forgettable Tale of a Drunken Dwarf",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 42, SkillId: 4, SkillName: "Cooking", Level: 22, Boostable: false },
      { QuestId: 42, SkillId: 7, SkillName: "Farming", Level: 17, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 41,
        Name: "Fishing Contest",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 41, SkillId: 9, SkillName: "Fishing", Level: 10, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 128,
        Name: "The Giant Dwarf",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 128, SkillId: 14, SkillName: "Magic", Level: 33, Boostable: false },
          {
            QuestId: 128,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 16,
            Boostable: false,
          },
          {
            QuestId: 128,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 14,
            Boostable: false,
          },
          { QuestId: 128, SkillId: 5, SkillName: "Crafting", Level: 12, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 43,
    Name: "Garden of Tranquillity",
    Length: "Very long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 43, SkillId: 7, SkillName: "Farming", Level: 25, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 17,
        Name: "Creature of Fenkenstrain",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 17,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 25,
            Boostable: false,
          },
          { QuestId: 17, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 44,
    Name: "Gertrude\u0027s Cat",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 45,
    Name: "Ghosts Ahoy",
    Length: "Medium \u2013 Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 45, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
      { QuestId: 45, SkillId: 4, SkillName: "Cooking", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 136,
        Name: "The Restless Ghost",
        Length: "Short",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 46,
    Name: "Goblin Diplomacy",
    Length: "Short",
    QuestPoints: 5,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 47,
    Name: "Grim Tales",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 47, SkillId: 23, SkillName: "Woodcutting", Level: 71, Boostable: true },
      { QuestId: 47, SkillId: 1, SkillName: "Agility", Level: 59, Boostable: true },
      { QuestId: 47, SkillId: 22, SkillName: "Thieving", Level: 58, Boostable: true },
      { QuestId: 47, SkillId: 11, SkillName: "Herblore", Level: 52, Boostable: true },
      { QuestId: 47, SkillId: 7, SkillName: "Farming", Level: 45, Boostable: true },
    ],
    SubquestRequirements: [
      {
        QuestId: 151,
        Name: "Witch\u0027s House",
        Length: "Short",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 48,
    Name: "Haunted Mine",
    Length: "Medium \u2013 Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 48, SkillId: 5, SkillName: "Crafting", Level: 35, Boostable: false },
      { QuestId: 48, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 49,
    Name: "Hazeel Cult",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 50,
    Name: "Heroes\u0027 Quest",
    Length: "Medium \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 50,
        SkillId: 25,
        SkillName: "Quest Points",
        Level: 55,
        Boostable: false,
      },
      { QuestId: 50, SkillId: 4, SkillName: "Cooking", Level: 53, Boostable: true },
      { QuestId: 50, SkillId: 9, SkillName: "Fishing", Level: 53, Boostable: true },
      { QuestId: 50, SkillId: 15, SkillName: "Mining", Level: 50, Boostable: true },
      { QuestId: 50, SkillId: 11, SkillName: "Herblore", Level: 25, Boostable: true },
    ],
    SubquestRequirements: [
      {
        QuestId: 25,
        Name: "Dragon Slayer",
        Length: "Long",
        QuestPoints: 2,
        Members: "F2P",
        SkillRequirements: [
          {
            QuestId: 25,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 32,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 60,
        Name: "Lost City",
        Length: "Short",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 60,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 36,
            Boostable: false,
          },
          { QuestId: 60, SkillId: 5, SkillName: "Crafting", Level: 31, Boostable: true },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 64,
        Name: "Merlin\u0027s Crystal",
        Length: "Medium",
        QuestPoints: 6,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 109,
        Name: "Shield of Arrav",
        Length: "Medium",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 51,
    Name: "Holy Grail",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 51, SkillId: 2, SkillName: "Attack", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 64,
        Name: "Merlin\u0027s Crystal",
        Length: "Medium",
        QuestPoints: 6,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 52,
    Name: "Horror from the Deep",
    Length: "Short",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 52, SkillId: 1, SkillName: "Agility", Level: 35, Boostable: true },
    ],
    SubquestRequirements: [
      {
        QuestId: 156,
        Name: "Alfred Grimhand\u0027s Barcrawl",
        Length: "Medium",
        QuestPoints: 0,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 53,
    Name: "Icthlarin\u0027s Little Helper",
    Length: "Medium \u2013 Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 44,
        Name: "Gertrude\u0027s Cat",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 54,
    Name: "Imp Catcher",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 55,
    Name: "In Aid of the Myreque",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 55, SkillId: 5, SkillName: "Crafting", Level: 25, Boostable: false },
      { QuestId: 55, SkillId: 15, SkillName: "Mining", Level: 15, Boostable: false },
      { QuestId: 55, SkillId: 14, SkillName: "Magic", Level: 7, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 56,
        Name: "In Search of the Myreque",
        Length: "Short",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 56, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 74,
            Name: "Nature Spirit",
            Length: "Medium",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 136,
                Name: "The Restless Ghost",
                Length: "Short",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 56,
    Name: "In Search of the Myreque",
    Length: "Short",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 56, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 74,
        Name: "Nature Spirit",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 57,
    Name: "Jungle Potion",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 57, SkillId: 11, SkillName: "Herblore", Level: 3, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 58,
    Name: "King\u0027s Ransom",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 58, SkillId: 6, SkillName: "Defence", Level: 65, Boostable: false },
      { QuestId: 58, SkillId: 14, SkillName: "Magic", Level: 45, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 9,
        Name: "Black Knights\u0027 Fortress",
        Length: "Short",
        QuestPoints: 3,
        Members: "F2P",
        SkillRequirements: [
          {
            QuestId: 9,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 12,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 51,
        Name: "Holy Grail",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 51, SkillId: 2, SkillName: "Attack", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 64,
            Name: "Merlin\u0027s Crystal",
            Length: "Medium",
            QuestPoints: 6,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 72,
        Name: "Murder Mystery",
        Length: "Short",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 77,
        Name: "One Small Favour",
        Length: "Very long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 77, SkillId: 1, SkillName: "Agility", Level: 36, Boostable: true },
          {
            QuestId: 77,
            SkillId: 20,
            SkillName: "Smithing",
            Level: 30,
            Boostable: false,
          },
          { QuestId: 77, SkillId: 5, SkillName: "Crafting", Level: 25, Boostable: false },
          { QuestId: 77, SkillId: 11, SkillName: "Herblore", Level: 18, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 28,
            Name: "Druidic Ritual",
            Length: "Short",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 110,
            Name: "Shilo Village",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 110,
                SkillId: 1,
                SkillName: "Agility",
                Level: 32,
                Boostable: true,
              },
              {
                QuestId: 110,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 59,
    Name: "Legends\u0027 Quest",
    Length: "Very long",
    QuestPoints: 4,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 59,
        SkillId: 25,
        SkillName: "Quest Points",
        Level: 107,
        Boostable: false,
      },
      { QuestId: 59, SkillId: 14, SkillName: "Magic", Level: 56, Boostable: false },
      { QuestId: 59, SkillId: 15, SkillName: "Mining", Level: 52, Boostable: true },
      { QuestId: 59, SkillId: 1, SkillName: "Agility", Level: 50, Boostable: true },
      { QuestId: 59, SkillId: 5, SkillName: "Crafting", Level: 50, Boostable: false },
      { QuestId: 59, SkillId: 20, SkillName: "Smithing", Level: 50, Boostable: false },
      { QuestId: 59, SkillId: 21, SkillName: "Strength", Level: 50, Boostable: false },
      { QuestId: 59, SkillId: 22, SkillName: "Thieving", Level: 50, Boostable: false },
      { QuestId: 59, SkillId: 23, SkillName: "Woodcutting", Level: 50, Boostable: false },
      { QuestId: 59, SkillId: 11, SkillName: "Herblore", Level: 45, Boostable: false },
      { QuestId: 59, SkillId: 16, SkillName: "Prayer", Level: 42, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 39,
        Name: "Family Crest",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 39, SkillId: 14, SkillName: "Magic", Level: 59, Boostable: true },
          { QuestId: 39, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: false },
          { QuestId: 39, SkillId: 15, SkillName: "Mining", Level: 40, Boostable: false },
          { QuestId: 39, SkillId: 20, SkillName: "Smithing", Level: 40, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 50,
        Name: "Heroes\u0027 Quest",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 50,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 55,
            Boostable: false,
          },
          { QuestId: 50, SkillId: 4, SkillName: "Cooking", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 9, SkillName: "Fishing", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 15, SkillName: "Mining", Level: 50, Boostable: true },
          { QuestId: 50, SkillId: 11, SkillName: "Herblore", Level: 25, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 25,
            Name: "Dragon Slayer",
            Length: "Long",
            QuestPoints: 2,
            Members: "F2P",
            SkillRequirements: [
              {
                QuestId: 25,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 32,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 60,
            Name: "Lost City",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 60,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 36,
                Boostable: false,
              },
              {
                QuestId: 60,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 31,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 64,
            Name: "Merlin\u0027s Crystal",
            Length: "Medium",
            QuestPoints: 6,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 109,
            Name: "Shield of Arrav",
            Length: "Medium",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 110,
        Name: "Shilo Village",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 110, SkillId: 1, SkillName: "Agility", Level: 32, Boostable: true },
          { QuestId: 110, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 145,
        Name: "Underground Pass",
        Length: "Long",
        QuestPoints: 5,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 145, SkillId: 17, SkillName: "Ranged", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 8,
            Name: "Biohazard",
            Length: "Medium",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 79,
                Name: "Plague City",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 149,
        Name: "Waterfall Quest",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 60,
    Name: "Lost City",
    Length: "Short",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 60, SkillId: 23, SkillName: "Woodcutting", Level: 36, Boostable: false },
      { QuestId: 60, SkillId: 5, SkillName: "Crafting", Level: 31, Boostable: true },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 61,
    Name: "Lunar Diplomacy",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 61, SkillId: 14, SkillName: "Magic", Level: 65, Boostable: false },
      { QuestId: 61, SkillId: 5, SkillName: "Crafting", Level: 61, Boostable: false },
      { QuestId: 61, SkillId: 15, SkillName: "Mining", Level: 60, Boostable: false },
      { QuestId: 61, SkillId: 23, SkillName: "Woodcutting", Level: 55, Boostable: false },
      { QuestId: 61, SkillId: 8, SkillName: "Firemaking", Level: 49, Boostable: false },
      { QuestId: 61, SkillId: 6, SkillName: "Defence", Level: 40, Boostable: false },
      { QuestId: 61, SkillId: 11, SkillName: "Herblore", Level: 5, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 60,
        Name: "Lost City",
        Length: "Short",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 60,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 36,
            Boostable: false,
          },
          { QuestId: 60, SkillId: 5, SkillName: "Crafting", Level: 31, Boostable: true },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 102,
        Name: "Rune Mysteries",
        Length: "Short (\u003C5 minutes)",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 110,
        Name: "Shilo Village",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 110, SkillId: 1, SkillName: "Agility", Level: 32, Boostable: true },
          { QuestId: 110, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 127,
        Name: "The Fremennik Trials",
        Length: "Long",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 127, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: true },
          {
            QuestId: 127,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 40,
            Boostable: false,
          },
          {
            QuestId: 127,
            SkillId: 10,
            SkillName: "Fletching",
            Level: 25,
            Boostable: true,
          },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 62,
    Name: "Making Friends with My Arm",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 62, SkillId: 15, SkillName: "Mining", Level: 72, Boostable: true },
      { QuestId: 62, SkillId: 1, SkillName: "Agility", Level: 68, Boostable: true },
      { QuestId: 62, SkillId: 8, SkillName: "Firemaking", Level: 66, Boostable: false },
      { QuestId: 62, SkillId: 3, SkillName: "Construction", Level: 35, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 14,
        Name: "Cold War",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 14,
            SkillId: 3,
            SkillName: "Construction",
            Level: 34,
            Boostable: true,
          },
          { QuestId: 14, SkillId: 1, SkillName: "Agility", Level: 30, Boostable: false },
          { QuestId: 14, SkillId: 5, SkillName: "Crafting", Level: 30, Boostable: false },
          {
            QuestId: 14,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 15,
            Boostable: false,
          },
          { QuestId: 14, SkillId: 13, SkillName: "Hunter", Level: 10, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 73,
        Name: "My Arm\u0027s Big Adventure",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 73, SkillId: 7, SkillName: "Farming", Level: 29, Boostable: false },
          {
            QuestId: 73,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 10,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 30,
            Name: "Eadgar\u0027s Ruse",
            Length: "Medium \u2013 Long",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 30,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 31,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 28,
                Name: "Druidic Ritual",
                Length: "Short",
                QuestPoints: 4,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 144,
                Name: "Troll Stronghold",
                Length: "Short \u2013 Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 144,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 15,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 19,
                    Name: "Death Plateau",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 123,
            Name: "The Feud",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 123,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 30,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 98,
        Name: "Romeo \u0026 Juliet",
        Length: "Short",
        QuestPoints: 5,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 113,
        Name: "Swan Song",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 113,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 100,
            Boostable: false,
          },
          { QuestId: 113, SkillId: 14, SkillName: "Magic", Level: 66, Boostable: true },
          { QuestId: 113, SkillId: 4, SkillName: "Cooking", Level: 62, Boostable: false },
          { QuestId: 113, SkillId: 9, SkillName: "Fishing", Level: 62, Boostable: true },
          {
            QuestId: 113,
            SkillId: 20,
            SkillName: "Smithing",
            Level: 45,
            Boostable: false,
          },
          {
            QuestId: 113,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 42,
            Boostable: false,
          },
          { QuestId: 113, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 43,
            Name: "Garden of Tranquillity",
            Length: "Very long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 43,
                SkillId: 7,
                SkillName: "Farming",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 17,
                Name: "Creature of Fenkenstrain",
                Length: "Medium",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 17,
                    SkillId: 22,
                    SkillName: "Thieving",
                    Level: 25,
                    Boostable: false,
                  },
                  {
                    QuestId: 17,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 20,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 80,
                    Name: "Priest in Peril",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 136,
                    Name: "The Restless Ghost",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "F2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 77,
            Name: "One Small Favour",
            Length: "Very long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 77,
                SkillId: 1,
                SkillName: "Agility",
                Level: 36,
                Boostable: true,
              },
              {
                QuestId: 77,
                SkillId: 20,
                SkillName: "Smithing",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 77,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 25,
                Boostable: false,
              },
              {
                QuestId: 77,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 18,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 28,
                Name: "Druidic Ritual",
                Length: "Short",
                QuestPoints: 4,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 102,
                Name: "Rune Mysteries",
                Length: "Short (\u003C5 minutes)",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 110,
                Name: "Shilo Village",
                Length: "Medium \u2013 Long",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 110,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 32,
                    Boostable: true,
                  },
                  {
                    QuestId: 110,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 20,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 57,
                    Name: "Jungle Potion",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 57,
                        SkillId: 11,
                        SkillName: "Herblore",
                        Level: 3,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 63,
    Name: "Making History",
    Length: "Short \u2013 Long",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 45,
        Name: "Ghosts Ahoy",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 45, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
          { QuestId: 45, SkillId: 4, SkillName: "Cooking", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 136,
        Name: "The Restless Ghost",
        Length: "Short",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 64,
    Name: "Merlin\u0027s Crystal",
    Length: "Medium",
    QuestPoints: 6,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 65,
    Name: "Misthalin Mystery",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 66,
    Name: "Monkey Madness I",
    Length: "Long",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 130,
        Name: "The Grand Tree",
        Length: "Medium",
        QuestPoints: 5,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 130, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 141,
        Name: "Tree Gnome Village",
        Length: "Short",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 67,
    Name: "Monkey Madness II",
    Length: "Very long",
    QuestPoints: 4,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 67, SkillId: 5, SkillName: "Crafting", Level: 70, Boostable: false },
      { QuestId: 67, SkillId: 19, SkillName: "Slayer", Level: 69, Boostable: false },
      { QuestId: 67, SkillId: 8, SkillName: "Firemaking", Level: 60, Boostable: true },
      { QuestId: 67, SkillId: 13, SkillName: "Hunter", Level: 60, Boostable: false },
      { QuestId: 67, SkillId: 1, SkillName: "Agility", Level: 55, Boostable: false },
      { QuestId: 67, SkillId: 22, SkillName: "Thieving", Level: 55, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 35,
        Name: "Enlightened Journey",
        Length: "Short \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 35, SkillId: 5, SkillName: "Crafting", Level: 36, Boostable: false },
          { QuestId: 35, SkillId: 7, SkillName: "Farming", Level: 30, Boostable: false },
          {
            QuestId: 35,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 20,
            Boostable: false,
          },
          {
            QuestId: 35,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 20,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 89,
        Name: "Recipe for Disaster/Freeing King Awowogei",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 89, SkillId: 4, SkillName: "Cooking", Level: 70, Boostable: true },
          { QuestId: 89, SkillId: 1, SkillName: "Agility", Level: 48, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 66,
            Name: "Monkey Madness I",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 130,
                Name: "The Grand Tree",
                Length: "Medium",
                QuestPoints: 5,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 130,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 25,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 141,
                Name: "Tree Gnome Village",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 122,
        Name: "The Eyes of Glouphrie",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 122, SkillId: 14, SkillName: "Magic", Level: 46, Boostable: false },
          {
            QuestId: 122,
            SkillId: 18,
            SkillName: "Runecrafting",
            Level: 13,
            Boostable: false,
          },
          {
            QuestId: 122,
            SkillId: 3,
            SkillName: "Construction",
            Level: 5,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 130,
            Name: "The Grand Tree",
            Length: "Medium",
            QuestPoints: 5,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 130,
                SkillId: 1,
                SkillName: "Agility",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 144,
        Name: "Troll Stronghold",
        Length: "Short \u2013 Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 144, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 19,
            Name: "Death Plateau",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 148,
        Name: "Watchtower",
        Length: "Long",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 148, SkillId: 15, SkillName: "Mining", Level: 40, Boostable: true },
          { QuestId: 148, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
          { QuestId: 148, SkillId: 14, SkillName: "Magic", Level: 15, Boostable: false },
          {
            QuestId: 148,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 15,
            Boostable: false,
          },
          {
            QuestId: 148,
            SkillId: 11,
            SkillName: "Herblore",
            Level: 14,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 68,
    Name: "Monk\u0027s Friend",
    Length: "Very short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 69,
    Name: "Mountain Daughter",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 69, SkillId: 1, SkillName: "Agility", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 70,
    Name: "Mourning\u0027s End Part I",
    Length: "Long \u2013 Very long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 70, SkillId: 17, SkillName: "Ranged", Level: 60, Boostable: false },
      { QuestId: 70, SkillId: 22, SkillName: "Thieving", Level: 50, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 7,
        Name: "Big Chompy Bird Hunting",
        Length: "Short",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 7, SkillId: 4, SkillName: "Cooking", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 10, SkillName: "Fletching", Level: 5, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 99,
        Name: "Roving Elves",
        Length: "Short \u2013 Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 97,
            Name: "Regicide",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 97,
                SkillId: 1,
                SkillName: "Agility",
                Level: 56,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 145,
                Name: "Underground Pass",
                Length: "Long",
                QuestPoints: 5,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 145,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 25,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 8,
                    Name: "Biohazard",
                    Length: "Medium",
                    QuestPoints: 3,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [
                      {
                        QuestId: 79,
                        Name: "Plague City",
                        Length: "Short",
                        QuestPoints: 1,
                        Members: "P2P",
                        SkillRequirements: [],
                        SubquestRequirements: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 149,
            Name: "Waterfall Quest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 107,
        Name: "Sheep Herder",
        Length: "Short/Medium",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 71,
    Name: "Mourning\u0027s End Part II",
    Length: "Very long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 70,
        Name: "Mourning\u0027s End Part I",
        Length: "Long \u2013 Very long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 70, SkillId: 17, SkillName: "Ranged", Level: 60, Boostable: false },
          { QuestId: 70, SkillId: 22, SkillName: "Thieving", Level: 50, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 7,
            Name: "Big Chompy Bird Hunting",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 7,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 99,
            Name: "Roving Elves",
            Length: "Short \u2013 Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 97,
                Name: "Regicide",
                Length: "Long",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 97,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 56,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 145,
                    Name: "Underground Pass",
                    Length: "Long",
                    QuestPoints: 5,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 145,
                        SkillId: 17,
                        SkillName: "Ranged",
                        Level: 25,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [
                      {
                        QuestId: 8,
                        Name: "Biohazard",
                        Length: "Medium",
                        QuestPoints: 3,
                        Members: "P2P",
                        SkillRequirements: [],
                        SubquestRequirements: [
                          {
                            QuestId: 79,
                            Name: "Plague City",
                            Length: "Short",
                            QuestPoints: 1,
                            Members: "P2P",
                            SkillRequirements: [],
                            SubquestRequirements: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                QuestId: 149,
                Name: "Waterfall Quest",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 107,
            Name: "Sheep Herder",
            Length: "Short/Medium",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 72,
    Name: "Murder Mystery",
    Length: "Short",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 73,
    Name: "My Arm\u0027s Big Adventure",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 73, SkillId: 7, SkillName: "Farming", Level: 29, Boostable: false },
      { QuestId: 73, SkillId: 23, SkillName: "Woodcutting", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 30,
        Name: "Eadgar\u0027s Ruse",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 30, SkillId: 11, SkillName: "Herblore", Level: 31, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 28,
            Name: "Druidic Ritual",
            Length: "Short",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 144,
            Name: "Troll Stronghold",
            Length: "Short \u2013 Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 144,
                SkillId: 1,
                SkillName: "Agility",
                Level: 15,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 19,
                Name: "Death Plateau",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 57,
        Name: "Jungle Potion",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 57, SkillId: 11, SkillName: "Herblore", Level: 3, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 123,
        Name: "The Feud",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 123,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 30,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 74,
    Name: "Nature Spirit",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 136,
        Name: "The Restless Ghost",
        Length: "Short",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 75,
    Name: "Observatory Quest",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 75, SkillId: 5, SkillName: "Crafting", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 76,
    Name: "Olaf\u0027s Quest",
    Length: "Short \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 76, SkillId: 23, SkillName: "Woodcutting", Level: 50, Boostable: false },
      { QuestId: 76, SkillId: 8, SkillName: "Firemaking", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 77,
    Name: "One Small Favour",
    Length: "Very long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 77, SkillId: 1, SkillName: "Agility", Level: 36, Boostable: true },
      { QuestId: 77, SkillId: 20, SkillName: "Smithing", Level: 30, Boostable: false },
      { QuestId: 77, SkillId: 5, SkillName: "Crafting", Level: 25, Boostable: false },
      { QuestId: 77, SkillId: 11, SkillName: "Herblore", Level: 18, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 28,
        Name: "Druidic Ritual",
        Length: "Short",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 102,
        Name: "Rune Mysteries",
        Length: "Short (\u003C5 minutes)",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 110,
        Name: "Shilo Village",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 110, SkillId: 1, SkillName: "Agility", Level: 32, Boostable: true },
          { QuestId: 110, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 78,
    Name: "Pirate\u0027s Treasure",
    Length: "Short",
    QuestPoints: 2,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 79,
    Name: "Plague City",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 80,
    Name: "Priest in Peril",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 81,
    Name: "Prince Ali Rescue",
    Length: "Short",
    QuestPoints: 3,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 82,
    Name: "Rag and Bone Man",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 83,
    Name: "Rag and Bone Man II",
    Length: "Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 83, SkillId: 19, SkillName: "Slayer", Level: 40, Boostable: false },
      { QuestId: 83, SkillId: 6, SkillName: "Defence", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 17,
        Name: "Creature of Fenkenstrain",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 17,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 25,
            Boostable: false,
          },
          { QuestId: 17, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 82,
        Name: "Rag and Bone Man",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 157,
        Name: "Skippy and the Mogres",
        Length: "Very short",
        QuestPoints: 0,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 149,
        Name: "Waterfall Quest",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 154,
        Name: "Zogre Flesh Eaters",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 154,
            SkillId: 10,
            SkillName: "Fletching",
            Level: 30,
            Boostable: false,
          },
          { QuestId: 154, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
          {
            QuestId: 154,
            SkillId: 21,
            SkillName: "Strength",
            Level: 20,
            Boostable: false,
          },
          {
            QuestId: 154,
            SkillId: 11,
            SkillName: "Herblore",
            Level: 8,
            Boostable: false,
          },
          { QuestId: 154, SkillId: 20, SkillName: "Smithing", Level: 4, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 7,
            Name: "Big Chompy Bird Hunting",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 7,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 84,
    Name: "Ratcatchers",
    Length: "Long \u2013 Very long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 53,
        Name: "Icthlarin\u0027s Little Helper",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 44,
            Name: "Gertrude\u0027s Cat",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 128,
        Name: "The Giant Dwarf",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 128, SkillId: 14, SkillName: "Magic", Level: 33, Boostable: false },
          {
            QuestId: 128,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 16,
            Boostable: false,
          },
          {
            QuestId: 128,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 14,
            Boostable: false,
          },
          { QuestId: 128, SkillId: 5, SkillName: "Crafting", Level: 12, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 85,
    Name: "Recipe for Disaster",
    Length: "Very long",
    QuestPoints: 10,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 86,
        Name: "Recipe for Disaster/Another Cook\u0027s Quest",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 86, SkillId: 4, SkillName: "Cooking", Level: 10, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 16,
            Name: "Cook\u0027s Assistant",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 87,
        Name: "Recipe for Disaster/Defeating the Culinaromancer",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 87,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 175,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 22,
            Name: "Desert Treasure",
            Length: "Very long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 22,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 53,
                Boostable: false,
              },
              {
                QuestId: 22,
                SkillId: 8,
                SkillName: "Firemaking",
                Level: 50,
                Boostable: false,
              },
              {
                QuestId: 22,
                SkillId: 14,
                SkillName: "Magic",
                Level: 50,
                Boostable: false,
              },
              {
                QuestId: 22,
                SkillId: 19,
                SkillName: "Slayer",
                Level: 10,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 117,
                Name: "Temple of Ikov",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 117,
                    SkillId: 22,
                    SkillName: "Thieving",
                    Level: 42,
                    Boostable: false,
                  },
                  {
                    QuestId: 117,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 40,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 121,
                Name: "The Dig Site",
                Length: "Long",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 121,
                    SkillId: 22,
                    SkillName: "Thieving",
                    Level: 25,
                    Boostable: false,
                  },
                  {
                    QuestId: 121,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 10,
                    Boostable: false,
                  },
                  {
                    QuestId: 121,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 10,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 138,
                Name: "The Tourist Trap",
                Length: "Medium",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 138,
                    SkillId: 20,
                    SkillName: "Smithing",
                    Level: 20,
                    Boostable: false,
                  },
                  {
                    QuestId: 138,
                    SkillId: 10,
                    SkillName: "Fletching",
                    Level: 10,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 144,
                Name: "Troll Stronghold",
                Length: "Short \u2013 Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 144,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 15,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 19,
                    Name: "Death Plateau",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
              {
                QuestId: 149,
                Name: "Waterfall Quest",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 52,
            Name: "Horror from the Deep",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 52,
                SkillId: 1,
                SkillName: "Agility",
                Level: 35,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 156,
                Name: "Alfred Grimhand\u0027s Barcrawl",
                Length: "Medium",
                QuestPoints: 0,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 88,
            Name: "Recipe for Disaster/Freeing Evil Dave",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 88,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 44,
                Name: "Gertrude\u0027s Cat",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 106,
                Name: "Shadow of the Storm",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 106,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 30,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 21,
                    Name: "Demon Slayer",
                    Length: "Medium",
                    QuestPoints: 3,
                    Members: "F2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 129,
                    Name: "The Golem",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 129,
                        SkillId: 22,
                        SkillName: "Thieving",
                        Level: 25,
                        Boostable: false,
                      },
                      {
                        QuestId: 129,
                        SkillId: 5,
                        SkillName: "Crafting",
                        Level: 20,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 89,
            Name: "Recipe for Disaster/Freeing King Awowogei",
            Length: "Medium \u2013 Long",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 89,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 70,
                Boostable: true,
              },
              {
                QuestId: 89,
                SkillId: 1,
                SkillName: "Agility",
                Level: 48,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 66,
                Name: "Monkey Madness I",
                Length: "Long",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 130,
                    Name: "The Grand Tree",
                    Length: "Medium",
                    QuestPoints: 5,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 130,
                        SkillId: 1,
                        SkillName: "Agility",
                        Level: 25,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 141,
                    Name: "Tree Gnome Village",
                    Length: "Short",
                    QuestPoints: 2,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 90,
            Name: "Recipe for Disaster/Freeing Pirate Pete",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 90,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 31,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 91,
            Name: "Recipe for Disaster/Freeing Sir Amik Varze",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 39,
                Name: "Family Crest",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 39,
                    SkillId: 14,
                    SkillName: "Magic",
                    Level: 59,
                    Boostable: true,
                  },
                  {
                    QuestId: 39,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 40,
                    Boostable: false,
                  },
                  {
                    QuestId: 39,
                    SkillId: 15,
                    SkillName: "Mining",
                    Level: 40,
                    Boostable: false,
                  },
                  {
                    QuestId: 39,
                    SkillId: 20,
                    SkillName: "Smithing",
                    Level: 40,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 50,
                Name: "Heroes\u0027 Quest",
                Length: "Medium \u2013 Long",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 50,
                    SkillId: 25,
                    SkillName: "Quest Points",
                    Level: 55,
                    Boostable: false,
                  },
                  {
                    QuestId: 50,
                    SkillId: 4,
                    SkillName: "Cooking",
                    Level: 53,
                    Boostable: true,
                  },
                  {
                    QuestId: 50,
                    SkillId: 9,
                    SkillName: "Fishing",
                    Level: 53,
                    Boostable: true,
                  },
                  {
                    QuestId: 50,
                    SkillId: 15,
                    SkillName: "Mining",
                    Level: 50,
                    Boostable: true,
                  },
                  {
                    QuestId: 50,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 25,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 25,
                    Name: "Dragon Slayer",
                    Length: "Long",
                    QuestPoints: 2,
                    Members: "F2P",
                    SkillRequirements: [
                      {
                        QuestId: 25,
                        SkillId: 25,
                        SkillName: "Quest Points",
                        Level: 32,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 60,
                    Name: "Lost City",
                    Length: "Short",
                    QuestPoints: 3,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 60,
                        SkillId: 23,
                        SkillName: "Woodcutting",
                        Level: 36,
                        Boostable: false,
                      },
                      {
                        QuestId: 60,
                        SkillId: 5,
                        SkillName: "Crafting",
                        Level: 31,
                        Boostable: true,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 64,
                    Name: "Merlin\u0027s Crystal",
                    Length: "Medium",
                    QuestPoints: 6,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 109,
                    Name: "Shield of Arrav",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "F2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
              {
                QuestId: 110,
                Name: "Shilo Village",
                Length: "Medium \u2013 Long",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 110,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 32,
                    Boostable: true,
                  },
                  {
                    QuestId: 110,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 20,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 57,
                    Name: "Jungle Potion",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 57,
                        SkillId: 11,
                        SkillName: "Herblore",
                        Level: 3,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                ],
              },
              {
                QuestId: 145,
                Name: "Underground Pass",
                Length: "Long",
                QuestPoints: 5,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 145,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 25,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 8,
                    Name: "Biohazard",
                    Length: "Medium",
                    QuestPoints: 3,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [
                      {
                        QuestId: 79,
                        Name: "Plague City",
                        Length: "Short",
                        QuestPoints: 1,
                        Members: "P2P",
                        SkillRequirements: [],
                        SubquestRequirements: [],
                      },
                    ],
                  },
                ],
              },
              {
                QuestId: 149,
                Name: "Waterfall Quest",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 92,
            Name: "Recipe for Disaster/Freeing Skrach Uglogwee",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 92,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 41,
                Boostable: false,
              },
              {
                QuestId: 92,
                SkillId: 8,
                SkillName: "Firemaking",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 7,
                Name: "Big Chompy Bird Hunting",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 7,
                    SkillId: 4,
                    SkillName: "Cooking",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 10,
                    SkillName: "Fletching",
                    Level: 5,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 93,
            Name: "Recipe for Disaster/Freeing the Goblin generals",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 46,
                Name: "Goblin Diplomacy",
                Length: "Short",
                QuestPoints: 5,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 94,
            Name: "Recipe for Disaster/Freeing the Lumbridge Guide",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 94,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 40,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 7,
                Name: "Big Chompy Bird Hunting",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 7,
                    SkillId: 4,
                    SkillName: "Cooking",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 10,
                    SkillName: "Fletching",
                    Level: 5,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 8,
                Name: "Biohazard",
                Length: "Medium",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 79,
                    Name: "Plague City",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
              {
                QuestId: 21,
                Name: "Demon Slayer",
                Length: "Medium",
                QuestPoints: 3,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 72,
                Name: "Murder Mystery",
                Length: "Short",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 74,
                Name: "Nature Spirit",
                Length: "Medium",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 80,
                    Name: "Priest in Peril",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 136,
                    Name: "The Restless Ghost",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "F2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
              {
                QuestId: 151,
                Name: "Witch\u0027s House",
                Length: "Short",
                QuestPoints: 4,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 95,
            Name: "Recipe for Disaster/Freeing the Mountain Dwarf",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 41,
                Name: "Fishing Contest",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 41,
                    SkillId: 9,
                    SkillName: "Fishing",
                    Level: 10,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 88,
        Name: "Recipe for Disaster/Freeing Evil Dave",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 88, SkillId: 4, SkillName: "Cooking", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 44,
            Name: "Gertrude\u0027s Cat",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 106,
            Name: "Shadow of the Storm",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 106,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 30,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 21,
                Name: "Demon Slayer",
                Length: "Medium",
                QuestPoints: 3,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 129,
                Name: "The Golem",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 129,
                    SkillId: 22,
                    SkillName: "Thieving",
                    Level: 25,
                    Boostable: false,
                  },
                  {
                    QuestId: 129,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 20,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 89,
        Name: "Recipe for Disaster/Freeing King Awowogei",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 89, SkillId: 4, SkillName: "Cooking", Level: 70, Boostable: true },
          { QuestId: 89, SkillId: 1, SkillName: "Agility", Level: 48, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 66,
            Name: "Monkey Madness I",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 130,
                Name: "The Grand Tree",
                Length: "Medium",
                QuestPoints: 5,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 130,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 25,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 141,
                Name: "Tree Gnome Village",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 90,
        Name: "Recipe for Disaster/Freeing Pirate Pete",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 90, SkillId: 4, SkillName: "Cooking", Level: 31, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 91,
        Name: "Recipe for Disaster/Freeing Sir Amik Varze",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 39,
            Name: "Family Crest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 39,
                SkillId: 14,
                SkillName: "Magic",
                Level: 59,
                Boostable: true,
              },
              {
                QuestId: 39,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 39,
                SkillId: 15,
                SkillName: "Mining",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 39,
                SkillId: 20,
                SkillName: "Smithing",
                Level: 40,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 50,
            Name: "Heroes\u0027 Quest",
            Length: "Medium \u2013 Long",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 50,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 55,
                Boostable: false,
              },
              {
                QuestId: 50,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 9,
                SkillName: "Fishing",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 15,
                SkillName: "Mining",
                Level: 50,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 25,
                Name: "Dragon Slayer",
                Length: "Long",
                QuestPoints: 2,
                Members: "F2P",
                SkillRequirements: [
                  {
                    QuestId: 25,
                    SkillId: 25,
                    SkillName: "Quest Points",
                    Level: 32,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 60,
                Name: "Lost City",
                Length: "Short",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 60,
                    SkillId: 23,
                    SkillName: "Woodcutting",
                    Level: 36,
                    Boostable: false,
                  },
                  {
                    QuestId: 60,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 31,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 64,
                Name: "Merlin\u0027s Crystal",
                Length: "Medium",
                QuestPoints: 6,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 109,
                Name: "Shield of Arrav",
                Length: "Medium",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 110,
            Name: "Shilo Village",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 110,
                SkillId: 1,
                SkillName: "Agility",
                Level: 32,
                Boostable: true,
              },
              {
                QuestId: 110,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 145,
            Name: "Underground Pass",
            Length: "Long",
            QuestPoints: 5,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 145,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 8,
                Name: "Biohazard",
                Length: "Medium",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 79,
                    Name: "Plague City",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 149,
            Name: "Waterfall Quest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 92,
        Name: "Recipe for Disaster/Freeing Skrach Uglogwee",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 92, SkillId: 4, SkillName: "Cooking", Level: 41, Boostable: false },
          {
            QuestId: 92,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 20,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 7,
            Name: "Big Chompy Bird Hunting",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 7,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 93,
        Name: "Recipe for Disaster/Freeing the Goblin generals",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 46,
            Name: "Goblin Diplomacy",
            Length: "Short",
            QuestPoints: 5,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 94,
        Name: "Recipe for Disaster/Freeing the Lumbridge Guide",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 94, SkillId: 4, SkillName: "Cooking", Level: 40, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 7,
            Name: "Big Chompy Bird Hunting",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 7,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 8,
            Name: "Biohazard",
            Length: "Medium",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 79,
                Name: "Plague City",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 21,
            Name: "Demon Slayer",
            Length: "Medium",
            QuestPoints: 3,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 72,
            Name: "Murder Mystery",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 74,
            Name: "Nature Spirit",
            Length: "Medium",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 136,
                Name: "The Restless Ghost",
                Length: "Short",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 151,
            Name: "Witch\u0027s House",
            Length: "Short",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 95,
        Name: "Recipe for Disaster/Freeing the Mountain Dwarf",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 41,
            Name: "Fishing Contest",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 41,
                SkillId: 9,
                SkillName: "Fishing",
                Level: 10,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 86,
    Name: "Recipe for Disaster/Another Cook\u0027s Quest",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 86, SkillId: 4, SkillName: "Cooking", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 16,
        Name: "Cook\u0027s Assistant",
        Length: "Short",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 87,
    Name: "Recipe for Disaster/Defeating the Culinaromancer",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 87,
        SkillId: 25,
        SkillName: "Quest Points",
        Level: 175,
        Boostable: false,
      },
    ],
    SubquestRequirements: [
      {
        QuestId: 22,
        Name: "Desert Treasure",
        Length: "Very long",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 22,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 53,
            Boostable: false,
          },
          {
            QuestId: 22,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 50,
            Boostable: false,
          },
          { QuestId: 22, SkillId: 14, SkillName: "Magic", Level: 50, Boostable: false },
          { QuestId: 22, SkillId: 19, SkillName: "Slayer", Level: 10, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 117,
            Name: "Temple of Ikov",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 117,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 42,
                Boostable: false,
              },
              {
                QuestId: 117,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 40,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 121,
            Name: "The Dig Site",
            Length: "Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 121,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 25,
                Boostable: false,
              },
              {
                QuestId: 121,
                SkillId: 1,
                SkillName: "Agility",
                Level: 10,
                Boostable: false,
              },
              {
                QuestId: 121,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 10,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 138,
            Name: "The Tourist Trap",
            Length: "Medium",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 138,
                SkillId: 20,
                SkillName: "Smithing",
                Level: 20,
                Boostable: false,
              },
              {
                QuestId: 138,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 10,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 144,
            Name: "Troll Stronghold",
            Length: "Short \u2013 Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 144,
                SkillId: 1,
                SkillName: "Agility",
                Level: 15,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 19,
                Name: "Death Plateau",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 149,
            Name: "Waterfall Quest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 52,
        Name: "Horror from the Deep",
        Length: "Short",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 52, SkillId: 1, SkillName: "Agility", Level: 35, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 156,
            Name: "Alfred Grimhand\u0027s Barcrawl",
            Length: "Medium",
            QuestPoints: 0,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 88,
        Name: "Recipe for Disaster/Freeing Evil Dave",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 88, SkillId: 4, SkillName: "Cooking", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 44,
            Name: "Gertrude\u0027s Cat",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 106,
            Name: "Shadow of the Storm",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 106,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 30,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 21,
                Name: "Demon Slayer",
                Length: "Medium",
                QuestPoints: 3,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 129,
                Name: "The Golem",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 129,
                    SkillId: 22,
                    SkillName: "Thieving",
                    Level: 25,
                    Boostable: false,
                  },
                  {
                    QuestId: 129,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 20,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 89,
        Name: "Recipe for Disaster/Freeing King Awowogei",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 89, SkillId: 4, SkillName: "Cooking", Level: 70, Boostable: true },
          { QuestId: 89, SkillId: 1, SkillName: "Agility", Level: 48, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 66,
            Name: "Monkey Madness I",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 130,
                Name: "The Grand Tree",
                Length: "Medium",
                QuestPoints: 5,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 130,
                    SkillId: 1,
                    SkillName: "Agility",
                    Level: 25,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 141,
                Name: "Tree Gnome Village",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 90,
        Name: "Recipe for Disaster/Freeing Pirate Pete",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 90, SkillId: 4, SkillName: "Cooking", Level: 31, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 91,
        Name: "Recipe for Disaster/Freeing Sir Amik Varze",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 39,
            Name: "Family Crest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 39,
                SkillId: 14,
                SkillName: "Magic",
                Level: 59,
                Boostable: true,
              },
              {
                QuestId: 39,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 39,
                SkillId: 15,
                SkillName: "Mining",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 39,
                SkillId: 20,
                SkillName: "Smithing",
                Level: 40,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 50,
            Name: "Heroes\u0027 Quest",
            Length: "Medium \u2013 Long",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 50,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 55,
                Boostable: false,
              },
              {
                QuestId: 50,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 9,
                SkillName: "Fishing",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 15,
                SkillName: "Mining",
                Level: 50,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 25,
                Name: "Dragon Slayer",
                Length: "Long",
                QuestPoints: 2,
                Members: "F2P",
                SkillRequirements: [
                  {
                    QuestId: 25,
                    SkillId: 25,
                    SkillName: "Quest Points",
                    Level: 32,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 60,
                Name: "Lost City",
                Length: "Short",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 60,
                    SkillId: 23,
                    SkillName: "Woodcutting",
                    Level: 36,
                    Boostable: false,
                  },
                  {
                    QuestId: 60,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 31,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 64,
                Name: "Merlin\u0027s Crystal",
                Length: "Medium",
                QuestPoints: 6,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 109,
                Name: "Shield of Arrav",
                Length: "Medium",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 110,
            Name: "Shilo Village",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 110,
                SkillId: 1,
                SkillName: "Agility",
                Level: 32,
                Boostable: true,
              },
              {
                QuestId: 110,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 145,
            Name: "Underground Pass",
            Length: "Long",
            QuestPoints: 5,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 145,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 8,
                Name: "Biohazard",
                Length: "Medium",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 79,
                    Name: "Plague City",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
          {
            QuestId: 149,
            Name: "Waterfall Quest",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 92,
        Name: "Recipe for Disaster/Freeing Skrach Uglogwee",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 92, SkillId: 4, SkillName: "Cooking", Level: 41, Boostable: false },
          {
            QuestId: 92,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 20,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 7,
            Name: "Big Chompy Bird Hunting",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 7,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 93,
        Name: "Recipe for Disaster/Freeing the Goblin generals",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 46,
            Name: "Goblin Diplomacy",
            Length: "Short",
            QuestPoints: 5,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 94,
        Name: "Recipe for Disaster/Freeing the Lumbridge Guide",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 94, SkillId: 4, SkillName: "Cooking", Level: 40, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 7,
            Name: "Big Chompy Bird Hunting",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 7,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 8,
            Name: "Biohazard",
            Length: "Medium",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 79,
                Name: "Plague City",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 21,
            Name: "Demon Slayer",
            Length: "Medium",
            QuestPoints: 3,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 72,
            Name: "Murder Mystery",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 74,
            Name: "Nature Spirit",
            Length: "Medium",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 136,
                Name: "The Restless Ghost",
                Length: "Short",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 151,
            Name: "Witch\u0027s House",
            Length: "Short",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 95,
        Name: "Recipe for Disaster/Freeing the Mountain Dwarf",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 41,
            Name: "Fishing Contest",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 41,
                SkillId: 9,
                SkillName: "Fishing",
                Level: 10,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 88,
    Name: "Recipe for Disaster/Freeing Evil Dave",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 88, SkillId: 4, SkillName: "Cooking", Level: 25, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 44,
        Name: "Gertrude\u0027s Cat",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 106,
        Name: "Shadow of the Storm",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 106, SkillId: 5, SkillName: "Crafting", Level: 30, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 21,
            Name: "Demon Slayer",
            Length: "Medium",
            QuestPoints: 3,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 129,
            Name: "The Golem",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 129,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 25,
                Boostable: false,
              },
              {
                QuestId: 129,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 89,
    Name: "Recipe for Disaster/Freeing King Awowogei",
    Length: "Medium \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 89, SkillId: 4, SkillName: "Cooking", Level: 70, Boostable: true },
      { QuestId: 89, SkillId: 1, SkillName: "Agility", Level: 48, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 66,
        Name: "Monkey Madness I",
        Length: "Long",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 130,
            Name: "The Grand Tree",
            Length: "Medium",
            QuestPoints: 5,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 130,
                SkillId: 1,
                SkillName: "Agility",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 141,
            Name: "Tree Gnome Village",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 90,
    Name: "Recipe for Disaster/Freeing Pirate Pete",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 90, SkillId: 4, SkillName: "Cooking", Level: 31, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 91,
    Name: "Recipe for Disaster/Freeing Sir Amik Varze",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 39,
        Name: "Family Crest",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 39, SkillId: 14, SkillName: "Magic", Level: 59, Boostable: true },
          { QuestId: 39, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: false },
          { QuestId: 39, SkillId: 15, SkillName: "Mining", Level: 40, Boostable: false },
          { QuestId: 39, SkillId: 20, SkillName: "Smithing", Level: 40, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 50,
        Name: "Heroes\u0027 Quest",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 50,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 55,
            Boostable: false,
          },
          { QuestId: 50, SkillId: 4, SkillName: "Cooking", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 9, SkillName: "Fishing", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 15, SkillName: "Mining", Level: 50, Boostable: true },
          { QuestId: 50, SkillId: 11, SkillName: "Herblore", Level: 25, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 25,
            Name: "Dragon Slayer",
            Length: "Long",
            QuestPoints: 2,
            Members: "F2P",
            SkillRequirements: [
              {
                QuestId: 25,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 32,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 60,
            Name: "Lost City",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 60,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 36,
                Boostable: false,
              },
              {
                QuestId: 60,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 31,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 64,
            Name: "Merlin\u0027s Crystal",
            Length: "Medium",
            QuestPoints: 6,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 109,
            Name: "Shield of Arrav",
            Length: "Medium",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 110,
        Name: "Shilo Village",
        Length: "Medium \u2013 Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 110, SkillId: 1, SkillName: "Agility", Level: 32, Boostable: true },
          { QuestId: 110, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 145,
        Name: "Underground Pass",
        Length: "Long",
        QuestPoints: 5,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 145, SkillId: 17, SkillName: "Ranged", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 8,
            Name: "Biohazard",
            Length: "Medium",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 79,
                Name: "Plague City",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 149,
        Name: "Waterfall Quest",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 92,
    Name: "Recipe for Disaster/Freeing Skrach Uglogwee",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 92, SkillId: 4, SkillName: "Cooking", Level: 41, Boostable: false },
      { QuestId: 92, SkillId: 8, SkillName: "Firemaking", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 7,
        Name: "Big Chompy Bird Hunting",
        Length: "Short",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 7, SkillId: 4, SkillName: "Cooking", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 10, SkillName: "Fletching", Level: 5, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 93,
    Name: "Recipe for Disaster/Freeing the Goblin generals",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 46,
        Name: "Goblin Diplomacy",
        Length: "Short",
        QuestPoints: 5,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 94,
    Name: "Recipe for Disaster/Freeing the Lumbridge Guide",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 94, SkillId: 4, SkillName: "Cooking", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 7,
        Name: "Big Chompy Bird Hunting",
        Length: "Short",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 7, SkillId: 4, SkillName: "Cooking", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 10, SkillName: "Fletching", Level: 5, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 8,
        Name: "Biohazard",
        Length: "Medium",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 79,
            Name: "Plague City",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 21,
        Name: "Demon Slayer",
        Length: "Medium",
        QuestPoints: 3,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 72,
        Name: "Murder Mystery",
        Length: "Short",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 74,
        Name: "Nature Spirit",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 151,
        Name: "Witch\u0027s House",
        Length: "Short",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 95,
    Name: "Recipe for Disaster/Freeing the Mountain Dwarf",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 41,
        Name: "Fishing Contest",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 41, SkillId: 9, SkillName: "Fishing", Level: 10, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 96,
    Name: "Recruitment Drive",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 9,
        Name: "Black Knights\u0027 Fortress",
        Length: "Short",
        QuestPoints: 3,
        Members: "F2P",
        SkillRequirements: [
          {
            QuestId: 9,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 12,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 28,
        Name: "Druidic Ritual",
        Length: "Short",
        QuestPoints: 4,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 97,
    Name: "Regicide",
    Length: "Long",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 97, SkillId: 1, SkillName: "Agility", Level: 56, Boostable: true },
    ],
    SubquestRequirements: [
      {
        QuestId: 145,
        Name: "Underground Pass",
        Length: "Long",
        QuestPoints: 5,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 145, SkillId: 17, SkillName: "Ranged", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 8,
            Name: "Biohazard",
            Length: "Medium",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 79,
                Name: "Plague City",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 98,
    Name: "Romeo \u0026 Juliet",
    Length: "Short",
    QuestPoints: 5,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 99,
    Name: "Roving Elves",
    Length: "Short \u2013 Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 97,
        Name: "Regicide",
        Length: "Long",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 97, SkillId: 1, SkillName: "Agility", Level: 56, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 145,
            Name: "Underground Pass",
            Length: "Long",
            QuestPoints: 5,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 145,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 25,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 8,
                Name: "Biohazard",
                Length: "Medium",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 79,
                    Name: "Plague City",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        QuestId: 149,
        Name: "Waterfall Quest",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 100,
    Name: "Royal Trouble",
    Length: "Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 100, SkillId: 1, SkillName: "Agility", Level: 40, Boostable: true },
      { QuestId: 100, SkillId: 19, SkillName: "Slayer", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 139,
        Name: "Throne of Miscellania",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 50,
            Name: "Heroes\u0027 Quest",
            Length: "Medium \u2013 Long",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 50,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 55,
                Boostable: false,
              },
              {
                QuestId: 50,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 9,
                SkillName: "Fishing",
                Level: 53,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 15,
                SkillName: "Mining",
                Level: 50,
                Boostable: true,
              },
              {
                QuestId: 50,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 25,
                Name: "Dragon Slayer",
                Length: "Long",
                QuestPoints: 2,
                Members: "F2P",
                SkillRequirements: [
                  {
                    QuestId: 25,
                    SkillId: 25,
                    SkillName: "Quest Points",
                    Level: 32,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 60,
                Name: "Lost City",
                Length: "Short",
                QuestPoints: 3,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 60,
                    SkillId: 23,
                    SkillName: "Woodcutting",
                    Level: 36,
                    Boostable: false,
                  },
                  {
                    QuestId: 60,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 31,
                    Boostable: true,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 64,
                Name: "Merlin\u0027s Crystal",
                Length: "Medium",
                QuestPoints: 6,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 109,
                Name: "Shield of Arrav",
                Length: "Medium",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 127,
            Name: "The Fremennik Trials",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 127,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 40,
                Boostable: true,
              },
              {
                QuestId: 127,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 127,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 101,
    Name: "Rum Deal",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 101, SkillId: 9, SkillName: "Fishing", Level: 50, Boostable: true },
      { QuestId: 101, SkillId: 16, SkillName: "Prayer", Level: 47, Boostable: true },
      { QuestId: 101, SkillId: 5, SkillName: "Crafting", Level: 42, Boostable: false },
      { QuestId: 101, SkillId: 19, SkillName: "Slayer", Level: 42, Boostable: false },
      { QuestId: 101, SkillId: 7, SkillName: "Farming", Level: 40, Boostable: true },
    ],
    SubquestRequirements: [
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 154,
        Name: "Zogre Flesh Eaters",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 154,
            SkillId: 10,
            SkillName: "Fletching",
            Level: 30,
            Boostable: false,
          },
          { QuestId: 154, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
          {
            QuestId: 154,
            SkillId: 21,
            SkillName: "Strength",
            Level: 20,
            Boostable: false,
          },
          {
            QuestId: 154,
            SkillId: 11,
            SkillName: "Herblore",
            Level: 8,
            Boostable: false,
          },
          { QuestId: 154, SkillId: 20, SkillName: "Smithing", Level: 4, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 7,
            Name: "Big Chompy Bird Hunting",
            Length: "Short",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 7,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 30,
                Boostable: false,
              },
              {
                QuestId: 7,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 57,
            Name: "Jungle Potion",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 57,
                SkillId: 11,
                SkillName: "Herblore",
                Level: 3,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 102,
    Name: "Rune Mysteries",
    Length: "Short (\u003C5 minutes)",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 103,
    Name: "Scorpion Catcher",
    Length: "Short \u2013 Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 103, SkillId: 16, SkillName: "Prayer", Level: 31, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 104,
    Name: "Sea Slug",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 104, SkillId: 8, SkillName: "Firemaking", Level: 30, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 105,
    Name: "Shades of Mort\u0027ton",
    Length: "Short",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 105, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
      { QuestId: 105, SkillId: 11, SkillName: "Herblore", Level: 15, Boostable: false },
      { QuestId: 105, SkillId: 8, SkillName: "Firemaking", Level: 5, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 106,
    Name: "Shadow of the Storm",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 106, SkillId: 5, SkillName: "Crafting", Level: 30, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 21,
        Name: "Demon Slayer",
        Length: "Medium",
        QuestPoints: 3,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 129,
        Name: "The Golem",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 129,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 25,
            Boostable: false,
          },
          { QuestId: 129, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 107,
    Name: "Sheep Herder",
    Length: "Short/Medium",
    QuestPoints: 4,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 108,
    Name: "Sheep Shearer",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 109,
    Name: "Shield of Arrav",
    Length: "Medium",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 110,
    Name: "Shilo Village",
    Length: "Medium \u2013 Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 110, SkillId: 1, SkillName: "Agility", Level: 32, Boostable: true },
      { QuestId: 110, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 57,
        Name: "Jungle Potion",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 57, SkillId: 11, SkillName: "Herblore", Level: 3, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 111,
    Name: "Song of the Elves",
    Length: "Very long",
    QuestPoints: 4,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 111, SkillId: 1, SkillName: "Agility", Level: 70, Boostable: false },
      {
        QuestId: 111,
        SkillId: 3,
        SkillName: "Construction",
        Level: 70,
        Boostable: false,
      },
      { QuestId: 111, SkillId: 7, SkillName: "Farming", Level: 70, Boostable: false },
      { QuestId: 111, SkillId: 11, SkillName: "Herblore", Level: 70, Boostable: false },
      { QuestId: 111, SkillId: 13, SkillName: "Hunter", Level: 70, Boostable: false },
      { QuestId: 111, SkillId: 15, SkillName: "Mining", Level: 70, Boostable: false },
      { QuestId: 111, SkillId: 20, SkillName: "Smithing", Level: 70, Boostable: false },
      { QuestId: 111, SkillId: 23, SkillName: "Woodcutting", Level: 70, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 63,
        Name: "Making History",
        Length: "Short \u2013 Long",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 45,
            Name: "Ghosts Ahoy",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 45,
                SkillId: 1,
                SkillName: "Agility",
                Level: 25,
                Boostable: false,
              },
              {
                QuestId: 45,
                SkillId: 4,
                SkillName: "Cooking",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 136,
                Name: "The Restless Ghost",
                Length: "Short",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 71,
        Name: "Mourning\u0027s End Part II",
        Length: "Very long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 70,
            Name: "Mourning\u0027s End Part I",
            Length: "Long \u2013 Very long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 70,
                SkillId: 17,
                SkillName: "Ranged",
                Level: 60,
                Boostable: false,
              },
              {
                QuestId: 70,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 50,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 7,
                Name: "Big Chompy Bird Hunting",
                Length: "Short",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 7,
                    SkillId: 4,
                    SkillName: "Cooking",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 7,
                    SkillId: 10,
                    SkillName: "Fletching",
                    Level: 5,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 99,
                Name: "Roving Elves",
                Length: "Short \u2013 Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [
                  {
                    QuestId: 97,
                    Name: "Regicide",
                    Length: "Long",
                    QuestPoints: 3,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 97,
                        SkillId: 1,
                        SkillName: "Agility",
                        Level: 56,
                        Boostable: true,
                      },
                    ],
                    SubquestRequirements: [
                      {
                        QuestId: 145,
                        Name: "Underground Pass",
                        Length: "Long",
                        QuestPoints: 5,
                        Members: "P2P",
                        SkillRequirements: [
                          {
                            QuestId: 145,
                            SkillId: 17,
                            SkillName: "Ranged",
                            Level: 25,
                            Boostable: false,
                          },
                        ],
                        SubquestRequirements: [
                          {
                            QuestId: 8,
                            Name: "Biohazard",
                            Length: "Medium",
                            QuestPoints: 3,
                            Members: "P2P",
                            SkillRequirements: [],
                            SubquestRequirements: [
                              {
                                QuestId: 79,
                                Name: "Plague City",
                                Length: "Short",
                                QuestPoints: 1,
                                Members: "P2P",
                                SkillRequirements: [],
                                SubquestRequirements: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    QuestId: 149,
                    Name: "Waterfall Quest",
                    Length: "Medium",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [],
                    SubquestRequirements: [],
                  },
                ],
              },
              {
                QuestId: 107,
                Name: "Sheep Herder",
                Length: "Short/Medium",
                QuestPoints: 4,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 112,
    Name: "Spirits of the Elid",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 112, SkillId: 15, SkillName: "Mining", Level: 37, Boostable: false },
      { QuestId: 112, SkillId: 17, SkillName: "Ranged", Level: 37, Boostable: true },
      { QuestId: 112, SkillId: 22, SkillName: "Thieving", Level: 37, Boostable: true },
      { QuestId: 112, SkillId: 14, SkillName: "Magic", Level: 33, Boostable: true },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 113,
    Name: "Swan Song",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 113,
        SkillId: 25,
        SkillName: "Quest Points",
        Level: 100,
        Boostable: false,
      },
      { QuestId: 113, SkillId: 14, SkillName: "Magic", Level: 66, Boostable: true },
      { QuestId: 113, SkillId: 4, SkillName: "Cooking", Level: 62, Boostable: false },
      { QuestId: 113, SkillId: 9, SkillName: "Fishing", Level: 62, Boostable: true },
      { QuestId: 113, SkillId: 20, SkillName: "Smithing", Level: 45, Boostable: false },
      { QuestId: 113, SkillId: 8, SkillName: "Firemaking", Level: 42, Boostable: false },
      { QuestId: 113, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 43,
        Name: "Garden of Tranquillity",
        Length: "Very long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 43, SkillId: 7, SkillName: "Farming", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 17,
            Name: "Creature of Fenkenstrain",
            Length: "Medium",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 17,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 25,
                Boostable: false,
              },
              {
                QuestId: 17,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 136,
                Name: "The Restless Ghost",
                Length: "Short",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
      {
        QuestId: 77,
        Name: "One Small Favour",
        Length: "Very long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 77, SkillId: 1, SkillName: "Agility", Level: 36, Boostable: true },
          {
            QuestId: 77,
            SkillId: 20,
            SkillName: "Smithing",
            Level: 30,
            Boostable: false,
          },
          { QuestId: 77, SkillId: 5, SkillName: "Crafting", Level: 25, Boostable: false },
          { QuestId: 77, SkillId: 11, SkillName: "Herblore", Level: 18, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 28,
            Name: "Druidic Ritual",
            Length: "Short",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 110,
            Name: "Shilo Village",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 110,
                SkillId: 1,
                SkillName: "Agility",
                Level: 32,
                Boostable: true,
              },
              {
                QuestId: 110,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 114,
    Name: "Tai Bwo Wannai Trio",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 114, SkillId: 4, SkillName: "Cooking", Level: 30, Boostable: false },
      { QuestId: 114, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: false },
      { QuestId: 114, SkillId: 9, SkillName: "Fishing", Level: 5, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 57,
        Name: "Jungle Potion",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 57, SkillId: 11, SkillName: "Herblore", Level: 3, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 115,
    Name: "Tale of the Righteous",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 12,
        Name: "Client of Kourend",
        Length: "Medium (15-20 minutes)",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 116,
    Name: "Tears of Guthix",
    Length: "Very short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 116, SkillId: 8, SkillName: "Firemaking", Level: 49, Boostable: false },
      {
        QuestId: 116,
        SkillId: 25,
        SkillName: "Quest Points",
        Level: 43,
        Boostable: false,
      },
      { QuestId: 116, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
      { QuestId: 116, SkillId: 15, SkillName: "Mining", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 117,
    Name: "Temple of Ikov",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 117, SkillId: 22, SkillName: "Thieving", Level: 42, Boostable: false },
      { QuestId: 117, SkillId: 17, SkillName: "Ranged", Level: 40, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 118,
    Name: "The Ascent of Arceuus",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 118, SkillId: 13, SkillName: "Hunter", Level: 12, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 12,
        Name: "Client of Kourend",
        Length: "Medium (15-20 minutes)",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 119,
    Name: "The Corsair Curse",
    Length: "Short",
    QuestPoints: 2,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 120,
    Name: "The Depths of Despair",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 120,
        SkillId: 26,
        SkillName: "Hosidius Favour",
        Level: 20,
        Boostable: false,
      },
      { QuestId: 120, SkillId: 1, SkillName: "Agility", Level: 18, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 12,
        Name: "Client of Kourend",
        Length: "Medium (15-20 minutes)",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 121,
    Name: "The Dig Site",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 121, SkillId: 22, SkillName: "Thieving", Level: 25, Boostable: false },
      { QuestId: 121, SkillId: 1, SkillName: "Agility", Level: 10, Boostable: false },
      { QuestId: 121, SkillId: 11, SkillName: "Herblore", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 122,
    Name: "The Eyes of Glouphrie",
    Length: "Medium \u2013 Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 122, SkillId: 14, SkillName: "Magic", Level: 46, Boostable: false },
      {
        QuestId: 122,
        SkillId: 18,
        SkillName: "Runecrafting",
        Level: 13,
        Boostable: false,
      },
      { QuestId: 122, SkillId: 3, SkillName: "Construction", Level: 5, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 130,
        Name: "The Grand Tree",
        Length: "Medium",
        QuestPoints: 5,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 130, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 123,
    Name: "The Feud",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 123, SkillId: 22, SkillName: "Thieving", Level: 30, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 124,
    Name: "The Forsaken Tower",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 124,
        SkillId: 28,
        SkillName: "Lovakengj Favour",
        Level: 20,
        Boostable: false,
      },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 125,
    Name: "The Fremennik Exiles",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 125, SkillId: 5, SkillName: "Crafting", Level: 65, Boostable: false },
      { QuestId: 125, SkillId: 9, SkillName: "Fishing", Level: 60, Boostable: false },
      { QuestId: 125, SkillId: 19, SkillName: "Slayer", Level: 60, Boostable: false },
      { QuestId: 125, SkillId: 20, SkillName: "Smithing", Level: 60, Boostable: false },
      {
        QuestId: 125,
        SkillId: 18,
        SkillName: "Runecrafting",
        Level: 55,
        Boostable: false,
      },
    ],
    SubquestRequirements: [
      {
        QuestId: 50,
        Name: "Heroes\u0027 Quest",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 50,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 55,
            Boostable: false,
          },
          { QuestId: 50, SkillId: 4, SkillName: "Cooking", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 9, SkillName: "Fishing", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 15, SkillName: "Mining", Level: 50, Boostable: true },
          { QuestId: 50, SkillId: 11, SkillName: "Herblore", Level: 25, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 25,
            Name: "Dragon Slayer",
            Length: "Long",
            QuestPoints: 2,
            Members: "F2P",
            SkillRequirements: [
              {
                QuestId: 25,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 32,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 60,
            Name: "Lost City",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 60,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 36,
                Boostable: false,
              },
              {
                QuestId: 60,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 31,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 64,
            Name: "Merlin\u0027s Crystal",
            Length: "Medium",
            QuestPoints: 6,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 109,
            Name: "Shield of Arrav",
            Length: "Medium",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 61,
        Name: "Lunar Diplomacy",
        Length: "Long",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 61, SkillId: 14, SkillName: "Magic", Level: 65, Boostable: false },
          { QuestId: 61, SkillId: 5, SkillName: "Crafting", Level: 61, Boostable: false },
          { QuestId: 61, SkillId: 15, SkillName: "Mining", Level: 60, Boostable: false },
          {
            QuestId: 61,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 55,
            Boostable: false,
          },
          {
            QuestId: 61,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 49,
            Boostable: false,
          },
          { QuestId: 61, SkillId: 6, SkillName: "Defence", Level: 40, Boostable: false },
          { QuestId: 61, SkillId: 11, SkillName: "Herblore", Level: 5, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 60,
            Name: "Lost City",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 60,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 36,
                Boostable: false,
              },
              {
                QuestId: 60,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 31,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 110,
            Name: "Shilo Village",
            Length: "Medium \u2013 Long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 110,
                SkillId: 1,
                SkillName: "Agility",
                Level: 32,
                Boostable: true,
              },
              {
                QuestId: 110,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 20,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 57,
                Name: "Jungle Potion",
                Length: "Short",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 57,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 3,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 127,
            Name: "The Fremennik Trials",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 127,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 40,
                Boostable: true,
              },
              {
                QuestId: 127,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 127,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 69,
        Name: "Mountain Daughter",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 69, SkillId: 1, SkillName: "Agility", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 126,
        Name: "The Fremennik Isles",
        Length: "Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 126,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 56,
            Boostable: false,
          },
          {
            QuestId: 126,
            SkillId: 5,
            SkillName: "Crafting",
            Level: 46,
            Boostable: false,
          },
          { QuestId: 126, SkillId: 1, SkillName: "Agility", Level: 40, Boostable: true },
          {
            QuestId: 126,
            SkillId: 3,
            SkillName: "Construction",
            Level: 20,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 127,
            Name: "The Fremennik Trials",
            Length: "Long",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 127,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 40,
                Boostable: true,
              },
              {
                QuestId: 127,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 127,
                SkillId: 10,
                SkillName: "Fletching",
                Level: 25,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 126,
    Name: "The Fremennik Isles",
    Length: "Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 126,
        SkillId: 23,
        SkillName: "Woodcutting",
        Level: 56,
        Boostable: false,
      },
      { QuestId: 126, SkillId: 5, SkillName: "Crafting", Level: 46, Boostable: false },
      { QuestId: 126, SkillId: 1, SkillName: "Agility", Level: 40, Boostable: true },
      { QuestId: 126, SkillId: 3, SkillName: "Construction", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 127,
        Name: "The Fremennik Trials",
        Length: "Long",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 127, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: true },
          {
            QuestId: 127,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 40,
            Boostable: false,
          },
          {
            QuestId: 127,
            SkillId: 10,
            SkillName: "Fletching",
            Level: 25,
            Boostable: true,
          },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 127,
    Name: "The Fremennik Trials",
    Length: "Long",
    QuestPoints: 3,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 127, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: true },
      {
        QuestId: 127,
        SkillId: 23,
        SkillName: "Woodcutting",
        Level: 40,
        Boostable: false,
      },
      { QuestId: 127, SkillId: 10, SkillName: "Fletching", Level: 25, Boostable: true },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 128,
    Name: "The Giant Dwarf",
    Length: "Medium \u2013 Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 128, SkillId: 14, SkillName: "Magic", Level: 33, Boostable: false },
      { QuestId: 128, SkillId: 8, SkillName: "Firemaking", Level: 16, Boostable: false },
      { QuestId: 128, SkillId: 22, SkillName: "Thieving", Level: 14, Boostable: false },
      { QuestId: 128, SkillId: 5, SkillName: "Crafting", Level: 12, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 129,
    Name: "The Golem",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 129, SkillId: 22, SkillName: "Thieving", Level: 25, Boostable: false },
      { QuestId: 129, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 130,
    Name: "The Grand Tree",
    Length: "Medium",
    QuestPoints: 5,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 130, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 131,
    Name: "The Great Brain Robbery",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 131, SkillId: 16, SkillName: "Prayer", Level: 50, Boostable: false },
      {
        QuestId: 131,
        SkillId: 3,
        SkillName: "Construction",
        Level: 30,
        Boostable: false,
      },
      { QuestId: 131, SkillId: 5, SkillName: "Crafting", Level: 16, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 11,
        Name: "Cabin Fever",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 11,
            SkillId: 20,
            SkillName: "Smithing",
            Level: 50,
            Boostable: false,
          },
          { QuestId: 11, SkillId: 5, SkillName: "Crafting", Level: 45, Boostable: false },
          { QuestId: 11, SkillId: 1, SkillName: "Agility", Level: 42, Boostable: false },
          { QuestId: 11, SkillId: 17, SkillName: "Ranged", Level: 40, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 78,
            Name: "Pirate\u0027s Treasure",
            Length: "Short",
            QuestPoints: 2,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 101,
            Name: "Rum Deal",
            Length: "Medium",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 101,
                SkillId: 9,
                SkillName: "Fishing",
                Level: 50,
                Boostable: true,
              },
              {
                QuestId: 101,
                SkillId: 16,
                SkillName: "Prayer",
                Level: 47,
                Boostable: true,
              },
              {
                QuestId: 101,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 42,
                Boostable: false,
              },
              {
                QuestId: 101,
                SkillId: 19,
                SkillName: "Slayer",
                Level: 42,
                Boostable: false,
              },
              {
                QuestId: 101,
                SkillId: 7,
                SkillName: "Farming",
                Level: 40,
                Boostable: true,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 80,
                Name: "Priest in Peril",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 154,
                Name: "Zogre Flesh Eaters",
                Length: "Medium",
                QuestPoints: 1,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 154,
                    SkillId: 10,
                    SkillName: "Fletching",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 154,
                    SkillId: 17,
                    SkillName: "Ranged",
                    Level: 30,
                    Boostable: false,
                  },
                  {
                    QuestId: 154,
                    SkillId: 21,
                    SkillName: "Strength",
                    Level: 20,
                    Boostable: false,
                  },
                  {
                    QuestId: 154,
                    SkillId: 11,
                    SkillName: "Herblore",
                    Level: 8,
                    Boostable: false,
                  },
                  {
                    QuestId: 154,
                    SkillId: 20,
                    SkillName: "Smithing",
                    Level: 4,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 7,
                    Name: "Big Chompy Bird Hunting",
                    Length: "Short",
                    QuestPoints: 2,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 7,
                        SkillId: 4,
                        SkillName: "Cooking",
                        Level: 30,
                        Boostable: false,
                      },
                      {
                        QuestId: 7,
                        SkillId: 17,
                        SkillName: "Ranged",
                        Level: 30,
                        Boostable: false,
                      },
                      {
                        QuestId: 7,
                        SkillId: 10,
                        SkillName: "Fletching",
                        Level: 5,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                  {
                    QuestId: 57,
                    Name: "Jungle Potion",
                    Length: "Short",
                    QuestPoints: 1,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 57,
                        SkillId: 11,
                        SkillName: "Herblore",
                        Level: 3,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        QuestId: 17,
        Name: "Creature of Fenkenstrain",
        Length: "Medium",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 17,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 25,
            Boostable: false,
          },
          { QuestId: 17, SkillId: 5, SkillName: "Crafting", Level: 20, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 136,
            Name: "The Restless Ghost",
            Length: "Short",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 90,
        Name: "Recipe for Disaster/Freeing Pirate Pete",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 90, SkillId: 4, SkillName: "Cooking", Level: 31, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 132,
    Name: "The Hand in the Sand",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 132, SkillId: 5, SkillName: "Crafting", Level: 49, Boostable: false },
      { QuestId: 132, SkillId: 22, SkillName: "Thieving", Level: 17, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 133,
    Name: "The Knight\u0027s Sword",
    Length: "Medium",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [
      { QuestId: 133, SkillId: 15, SkillName: "Mining", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 134,
    Name: "The Lost Tribe",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 134, SkillId: 15, SkillName: "Mining", Level: 17, Boostable: false },
      { QuestId: 134, SkillId: 1, SkillName: "Agility", Level: 13, Boostable: false },
      { QuestId: 134, SkillId: 22, SkillName: "Thieving", Level: 13, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 46,
        Name: "Goblin Diplomacy",
        Length: "Short",
        QuestPoints: 5,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 102,
        Name: "Rune Mysteries",
        Length: "Short (\u003C5 minutes)",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 135,
    Name: "The Queen of Thieves",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 135,
        SkillId: 27,
        SkillName: "Port Piscarilius Favour",
        Level: 20,
        Boostable: false,
      },
    ],
    SubquestRequirements: [
      {
        QuestId: 12,
        Name: "Client of Kourend",
        Length: "Medium (15-20 minutes)",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 136,
    Name: "The Restless Ghost",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 137,
    Name: "The Slug Menace",
    Length: "Medium \u2013 Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 137, SkillId: 5, SkillName: "Crafting", Level: 30, Boostable: false },
      {
        QuestId: 137,
        SkillId: 18,
        SkillName: "Runecrafting",
        Level: 30,
        Boostable: false,
      },
      { QuestId: 137, SkillId: 19, SkillName: "Slayer", Level: 30, Boostable: false },
      { QuestId: 137, SkillId: 22, SkillName: "Thieving", Level: 30, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 104,
        Name: "Sea Slug",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 104,
            SkillId: 8,
            SkillName: "Firemaking",
            Level: 30,
            Boostable: false,
          },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 147,
        Name: "Wanted!",
        Length: "Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 147,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 32,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 155,
            Name: "Enter the Abyss",
            Length: "Short",
            QuestPoints: 0,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 102,
                Name: "Rune Mysteries",
                Length: "Short (\u003C5 minutes)",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 80,
            Name: "Priest in Peril",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 96,
            Name: "Recruitment Drive",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [
              {
                QuestId: 9,
                Name: "Black Knights\u0027 Fortress",
                Length: "Short",
                QuestPoints: 3,
                Members: "F2P",
                SkillRequirements: [
                  {
                    QuestId: 9,
                    SkillId: 25,
                    SkillName: "Quest Points",
                    Level: 12,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [],
              },
              {
                QuestId: 28,
                Name: "Druidic Ritual",
                Length: "Short",
                QuestPoints: 4,
                Members: "P2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
          {
            QuestId: 134,
            Name: "The Lost Tribe",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 134,
                SkillId: 15,
                SkillName: "Mining",
                Level: 17,
                Boostable: false,
              },
              {
                QuestId: 134,
                SkillId: 1,
                SkillName: "Agility",
                Level: 13,
                Boostable: false,
              },
              {
                QuestId: 134,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 13,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 46,
                Name: "Goblin Diplomacy",
                Length: "Short",
                QuestPoints: 5,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
              {
                QuestId: 102,
                Name: "Rune Mysteries",
                Length: "Short (\u003C5 minutes)",
                QuestPoints: 1,
                Members: "F2P",
                SkillRequirements: [],
                SubquestRequirements: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    QuestId: 138,
    Name: "The Tourist Trap",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 138, SkillId: 20, SkillName: "Smithing", Level: 20, Boostable: false },
      { QuestId: 138, SkillId: 10, SkillName: "Fletching", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 139,
    Name: "Throne of Miscellania",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 50,
        Name: "Heroes\u0027 Quest",
        Length: "Medium \u2013 Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          {
            QuestId: 50,
            SkillId: 25,
            SkillName: "Quest Points",
            Level: 55,
            Boostable: false,
          },
          { QuestId: 50, SkillId: 4, SkillName: "Cooking", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 9, SkillName: "Fishing", Level: 53, Boostable: true },
          { QuestId: 50, SkillId: 15, SkillName: "Mining", Level: 50, Boostable: true },
          { QuestId: 50, SkillId: 11, SkillName: "Herblore", Level: 25, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 25,
            Name: "Dragon Slayer",
            Length: "Long",
            QuestPoints: 2,
            Members: "F2P",
            SkillRequirements: [
              {
                QuestId: 25,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 32,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 60,
            Name: "Lost City",
            Length: "Short",
            QuestPoints: 3,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 60,
                SkillId: 23,
                SkillName: "Woodcutting",
                Level: 36,
                Boostable: false,
              },
              {
                QuestId: 60,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 31,
                Boostable: true,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 64,
            Name: "Merlin\u0027s Crystal",
            Length: "Medium",
            QuestPoints: 6,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 109,
            Name: "Shield of Arrav",
            Length: "Medium",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 127,
        Name: "The Fremennik Trials",
        Length: "Long",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 127, SkillId: 5, SkillName: "Crafting", Level: 40, Boostable: true },
          {
            QuestId: 127,
            SkillId: 23,
            SkillName: "Woodcutting",
            Level: 40,
            Boostable: false,
          },
          {
            QuestId: 127,
            SkillId: 10,
            SkillName: "Fletching",
            Level: 25,
            Boostable: true,
          },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 140,
    Name: "Tower of Life",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 140, SkillId: 3, SkillName: "Construction", Level: 10, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 141,
    Name: "Tree Gnome Village",
    Length: "Short",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 142,
    Name: "Tribal Totem",
    Length: "Short",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 142, SkillId: 22, SkillName: "Thieving", Level: 21, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 143,
    Name: "Troll Romance",
    Length: "Medium",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 143, SkillId: 1, SkillName: "Agility", Level: 28, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 144,
        Name: "Troll Stronghold",
        Length: "Short \u2013 Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 144, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: true },
        ],
        SubquestRequirements: [
          {
            QuestId: 19,
            Name: "Death Plateau",
            Length: "Medium",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 144,
    Name: "Troll Stronghold",
    Length: "Short \u2013 Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 144, SkillId: 1, SkillName: "Agility", Level: 15, Boostable: true },
    ],
    SubquestRequirements: [
      {
        QuestId: 19,
        Name: "Death Plateau",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 145,
    Name: "Underground Pass",
    Length: "Long",
    QuestPoints: 5,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 145, SkillId: 17, SkillName: "Ranged", Level: 25, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 8,
        Name: "Biohazard",
        Length: "Medium",
        QuestPoints: 3,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 79,
            Name: "Plague City",
            Length: "Short",
            QuestPoints: 1,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 146,
    Name: "Vampire Slayer",
    Length: "Short",
    QuestPoints: 3,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 147,
    Name: "Wanted!",
    Length: "Long",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 147,
        SkillId: 25,
        SkillName: "Quest Points",
        Level: 32,
        Boostable: false,
      },
    ],
    SubquestRequirements: [
      {
        QuestId: 155,
        Name: "Enter the Abyss",
        Length: "Short",
        QuestPoints: 0,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 80,
        Name: "Priest in Peril",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
      {
        QuestId: 96,
        Name: "Recruitment Drive",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [],
        SubquestRequirements: [
          {
            QuestId: 9,
            Name: "Black Knights\u0027 Fortress",
            Length: "Short",
            QuestPoints: 3,
            Members: "F2P",
            SkillRequirements: [
              {
                QuestId: 9,
                SkillId: 25,
                SkillName: "Quest Points",
                Level: 12,
                Boostable: false,
              },
            ],
            SubquestRequirements: [],
          },
          {
            QuestId: 28,
            Name: "Druidic Ritual",
            Length: "Short",
            QuestPoints: 4,
            Members: "P2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
      {
        QuestId: 134,
        Name: "The Lost Tribe",
        Length: "Medium",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 134, SkillId: 15, SkillName: "Mining", Level: 17, Boostable: false },
          { QuestId: 134, SkillId: 1, SkillName: "Agility", Level: 13, Boostable: false },
          {
            QuestId: 134,
            SkillId: 22,
            SkillName: "Thieving",
            Level: 13,
            Boostable: false,
          },
        ],
        SubquestRequirements: [
          {
            QuestId: 46,
            Name: "Goblin Diplomacy",
            Length: "Short",
            QuestPoints: 5,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
          {
            QuestId: 102,
            Name: "Rune Mysteries",
            Length: "Short (\u003C5 minutes)",
            QuestPoints: 1,
            Members: "F2P",
            SkillRequirements: [],
            SubquestRequirements: [],
          },
        ],
      },
    ],
  },
  {
    QuestId: 148,
    Name: "Watchtower",
    Length: "Long",
    QuestPoints: 4,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 148, SkillId: 15, SkillName: "Mining", Level: 40, Boostable: true },
      { QuestId: 148, SkillId: 1, SkillName: "Agility", Level: 25, Boostable: false },
      { QuestId: 148, SkillId: 14, SkillName: "Magic", Level: 15, Boostable: false },
      { QuestId: 148, SkillId: 22, SkillName: "Thieving", Level: 15, Boostable: false },
      { QuestId: 148, SkillId: 11, SkillName: "Herblore", Level: 14, Boostable: false },
    ],
    SubquestRequirements: [],
  },
  {
    QuestId: 149,
    Name: "Waterfall Quest",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 150,
    Name: "What Lies Below",
    Length: "Short \u2013 Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 150,
        SkillId: 18,
        SkillName: "Runecrafting",
        Level: 35,
        Boostable: false,
      },
    ],
    SubquestRequirements: [
      {
        QuestId: 102,
        Name: "Rune Mysteries",
        Length: "Short (\u003C5 minutes)",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 151,
    Name: "Witch\u0027s House",
    Length: "Short",
    QuestPoints: 4,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 152,
    Name: "Witch\u0027s Potion",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 153,
    Name: "X Marks the Spot",
    Length: "Short",
    QuestPoints: 1,
    Members: "F2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 154,
    Name: "Zogre Flesh Eaters",
    Length: "Medium",
    QuestPoints: 1,
    Members: "P2P",
    SkillRequirements: [
      { QuestId: 154, SkillId: 10, SkillName: "Fletching", Level: 30, Boostable: false },
      { QuestId: 154, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
      { QuestId: 154, SkillId: 21, SkillName: "Strength", Level: 20, Boostable: false },
      { QuestId: 154, SkillId: 11, SkillName: "Herblore", Level: 8, Boostable: false },
      { QuestId: 154, SkillId: 20, SkillName: "Smithing", Level: 4, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 7,
        Name: "Big Chompy Bird Hunting",
        Length: "Short",
        QuestPoints: 2,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 7, SkillId: 4, SkillName: "Cooking", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 17, SkillName: "Ranged", Level: 30, Boostable: false },
          { QuestId: 7, SkillId: 10, SkillName: "Fletching", Level: 5, Boostable: false },
        ],
        SubquestRequirements: [],
      },
      {
        QuestId: 57,
        Name: "Jungle Potion",
        Length: "Short",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 57, SkillId: 11, SkillName: "Herblore", Level: 3, Boostable: false },
        ],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 155,
    Name: "Enter the Abyss",
    Length: "Short",
    QuestPoints: 0,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [
      {
        QuestId: 102,
        Name: "Rune Mysteries",
        Length: "Short (\u003C5 minutes)",
        QuestPoints: 1,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
  {
    QuestId: 156,
    Name: "Alfred Grimhand\u0027s Barcrawl",
    Length: "Medium",
    QuestPoints: 0,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 157,
    Name: "Skippy and the Mogres",
    Length: "Very short",
    QuestPoints: 0,
    Members: "P2P",
    SkillRequirements: [],
    SubquestRequirements: [],
  },
  {
    QuestId: 158,
    Name: "Sins of the Father",
    Length: "Long",
    QuestPoints: 2,
    Members: "P2P",
    SkillRequirements: [
      {
        QuestId: 158,
        SkillId: 23,
        SkillName: "Woodcutting",
        Level: 62,
        Boostable: false,
      },
      { QuestId: 158, SkillId: 10, SkillName: "Fletching", Level: 60, Boostable: false },
      { QuestId: 158, SkillId: 5, SkillName: "Crafting", Level: 56, Boostable: false },
      { QuestId: 158, SkillId: 1, SkillName: "Agility", Level: 52, Boostable: false },
      { QuestId: 158, SkillId: 2, SkillName: "Attack", Level: 50, Boostable: false },
      { QuestId: 158, SkillId: 19, SkillName: "Slayer", Level: 50, Boostable: false },
      { QuestId: 158, SkillId: 14, SkillName: "Magic", Level: 49, Boostable: false },
    ],
    SubquestRequirements: [
      {
        QuestId: 3,
        Name: "A Taste of Hope",
        Length: "Long",
        QuestPoints: 1,
        Members: "P2P",
        SkillRequirements: [
          { QuestId: 3, SkillId: 5, SkillName: "Crafting", Level: 48, Boostable: false },
          { QuestId: 3, SkillId: 2, SkillName: "Attack", Level: 40, Boostable: false },
          { QuestId: 3, SkillId: 19, SkillName: "Slayer", Level: 38, Boostable: false },
        ],
        SubquestRequirements: [
          {
            QuestId: 18,
            Name: "Darkness of Hallowvale",
            Length: "Very long",
            QuestPoints: 2,
            Members: "P2P",
            SkillRequirements: [
              {
                QuestId: 18,
                SkillId: 21,
                SkillName: "Strength",
                Level: 40,
                Boostable: false,
              },
              {
                QuestId: 18,
                SkillId: 14,
                SkillName: "Magic",
                Level: 33,
                Boostable: false,
              },
              {
                QuestId: 18,
                SkillId: 5,
                SkillName: "Crafting",
                Level: 32,
                Boostable: false,
              },
              {
                QuestId: 18,
                SkillId: 1,
                SkillName: "Agility",
                Level: 26,
                Boostable: false,
              },
              {
                QuestId: 18,
                SkillId: 22,
                SkillName: "Thieving",
                Level: 22,
                Boostable: false,
              },
              {
                QuestId: 18,
                SkillId: 15,
                SkillName: "Mining",
                Level: 20,
                Boostable: false,
              },
              {
                QuestId: 18,
                SkillId: 3,
                SkillName: "Construction",
                Level: 5,
                Boostable: false,
              },
            ],
            SubquestRequirements: [
              {
                QuestId: 55,
                Name: "In Aid of the Myreque",
                Length: "Long",
                QuestPoints: 2,
                Members: "P2P",
                SkillRequirements: [
                  {
                    QuestId: 55,
                    SkillId: 5,
                    SkillName: "Crafting",
                    Level: 25,
                    Boostable: false,
                  },
                  {
                    QuestId: 55,
                    SkillId: 15,
                    SkillName: "Mining",
                    Level: 15,
                    Boostable: false,
                  },
                  {
                    QuestId: 55,
                    SkillId: 14,
                    SkillName: "Magic",
                    Level: 7,
                    Boostable: false,
                  },
                ],
                SubquestRequirements: [
                  {
                    QuestId: 56,
                    Name: "In Search of the Myreque",
                    Length: "Short",
                    QuestPoints: 2,
                    Members: "P2P",
                    SkillRequirements: [
                      {
                        QuestId: 56,
                        SkillId: 1,
                        SkillName: "Agility",
                        Level: 25,
                        Boostable: false,
                      },
                    ],
                    SubquestRequirements: [
                      {
                        QuestId: 74,
                        Name: "Nature Spirit",
                        Length: "Medium",
                        QuestPoints: 2,
                        Members: "P2P",
                        SkillRequirements: [],
                        SubquestRequirements: [
                          {
                            QuestId: 80,
                            Name: "Priest in Peril",
                            Length: "Medium",
                            QuestPoints: 1,
                            Members: "P2P",
                            SkillRequirements: [],
                            SubquestRequirements: [],
                          },
                          {
                            QuestId: 136,
                            Name: "The Restless Ghost",
                            Length: "Short",
                            QuestPoints: 1,
                            Members: "F2P",
                            SkillRequirements: [],
                            SubquestRequirements: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        QuestId: 146,
        Name: "Vampire Slayer",
        Length: "Short",
        QuestPoints: 3,
        Members: "F2P",
        SkillRequirements: [],
        SubquestRequirements: [],
      },
    ],
  },
];
