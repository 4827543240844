import React from "react";
import { Tooltip } from "antd";
import "../App.css";
import { InfoCircleOutlined } from "@ant-design/icons";
const QuestStatistic = (props) => {
  return (
    <div
      style={{
        paddingLeft: "12px",
        paddingRight: "12px",
        cursor: "default",
      }}
      class="ant-col"
    >
      <div class="ant-statistic">
        <div class="ant-statistic-title">
          {props.title}
          <Tooltip title={props.tooltip}>
            <InfoCircleOutlined
              style={{ cursor: "pointer", marginLeft: "5px" }}
            />
          </Tooltip>{" "}
        </div>
        <div class="ant-statistic-content">
          <span class="ant-statistic-content-value">
            <span class="ant-statistic-content-value-int">{props.value}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuestStatistic;
