import React, { useState } from "react";
import { Divider, Tooltip } from "antd";
import QuestFilter from "./QuestFilter";
import QuestTable from "./QuestTable";
import { InfoCircleOutlined } from "@ant-design/icons";

const MasterQuestList = (props) => {
  const [search, setSearch] = useState("");

  return (
    <div className="master-quest-list">
      <Divider>
        <span style={{ cursor: "pointer" }}>Master Quest List</span>
        <Tooltip title="A list of all quests in Runescape. Try marking some as planned.">
          <InfoCircleOutlined style={{ cursor: "pointer", marginLeft: "5px", fontSize: "14px" }} />
        </Tooltip>
      </Divider>
      <QuestFilter setSearch={setSearch} />
      <div style={{ height: "540px" }}>
        <QuestTable
          search={search}
          quests={props.quests}
          questStatus={props.questStatus}
          setQuestStatus={props.setQuestStatus}
          playerSkills={props.playerSkills}
          height={540}
        />
      </div>
    </div>
  );
};

export default MasterQuestList;
