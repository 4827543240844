import React, { useState } from "react";
import { Layout } from "antd";
import "../App.css";
import QuestPlanner from "./QuestPlanner";

const { Header, Content } = Layout;

const AppLayout = (props) => {
  return (
    <Layout style={{ minHeight: "100vh", minWidth: "355px" }}>
      <Header
        style={{
          height: "50px",
          paddingLeft: "15px",
          paddingTop: "10px",
          backgroundColor: "#1b3a57",
        }}
      >
        <div className="logo" />
      </Header>
      <Layout className="site-layout">
        <Content>
          <div className="site-layout-background" style={{ padding: 12, minHeight: 360 }}>
            <QuestPlanner />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
