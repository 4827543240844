import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Tooltip } from "antd";
import QuestStatistic from "./QuestStatistic";
import QuestTable from "./QuestTable";
import { filterQuestListByStatus, QuestStatus, getQuestPointsFromCompletedQuests } from "./Utils";
import { InfoCircleOutlined } from "@ant-design/icons";

const QuestPlan = (props) => {
  const [questPoints, setQuestPoints] = useState({ Planned: 0, Current: 0 });

  useEffect(() => {
    let qp = {};
    qp.completed = getCompletedQuestPoints();
    qp.planned = getPlannedQuestPoints();
    setQuestPoints(qp);
  }, [props.playerSkills, props.questStatus]);

  const getQuestsByStatus = (status) => {
    return filterQuestListByStatus(props.quests, props.questStatus, status);
  };

  const getCompletedQuestPoints = () => {
    //pull quest points from players entered skills
    let playerQuestPoints = props.playerSkills["Quest Points"];

    //if user has not entered any quest points, use completed quests instead
    if (playerQuestPoints === undefined || playerQuestPoints === 0) {
      playerQuestPoints = getQuestPointsFromCompletedQuests(props.quests, props.questStatus);
    }

    return playerQuestPoints;
  };

  const getPlannedQuestPoints = () => {
    let plannedQuestPoints = 0;
    let completedQuests = getQuestsByStatus(QuestStatus.Planned);
    completedQuests.forEach((quest) => {
      plannedQuestPoints += Number(quest.QuestPoints);
    });
    return plannedQuestPoints;
  };

  return (
    <div style={{ flex: "1" }}>
      <Divider>
        Planned Quests ({getQuestsByStatus(QuestStatus.Planned).length})
        <Tooltip title="A consolidated view of your quest plan, displaying only the top-most level quests.">
          <InfoCircleOutlined
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              fontSize: "14px",
            }}
          />
        </Tooltip>
      </Divider>
      <Row gutter={24} style={{ marginBottom: "22px", textAlign: "center" }}>
        <Col span={8}>
          <QuestStatistic
            title="Starting Quest Points"
            value={questPoints.completed}
            tooltip="Number calculated as either the quest points you have provided above, or as the quest points marked completed if no quest points are entered."
          />
        </Col>
        <Col span={8}>
          <QuestStatistic
            title="Planned Quest Points"
            value={questPoints.planned}
            tooltip="Total number of quest points marked as planned."
          />
        </Col>
        <Col span={8}>
          <QuestStatistic
            title="Final Quest Points"
            value={Number(questPoints.completed) + Number(questPoints.planned)}
            tooltip="Your quest points upon completion of all quests in your plan."
          />
        </Col>
      </Row>
      <QuestTable
        displayMode={true}
        quests={props.quests}
        questStatus={props.questStatus}
        setQuestStatus={props.setQuestStatus}
        playerSkills={props.playerSkills}
        height={497}
      />
    </div>
  );
};

export default QuestPlan;
