import React, { useEffect, useState } from "react";
import { Divider, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import SkillImages from "./SkillImages";
import {
  filterQuestListByStatus,
  QuestStatus,
  sortSkillsByDisplayOrder,
  getQuestPointsFromCompletedQuests,
} from "./Utils";
import "../App.css";

const QuestRequirements = (props) => {
  const [requirements, setRequirements] = useState({});

  useEffect(() => {
    calcRequirements();
  }, [props.playerSkills, props.questStatus]);

  const getQuestsByStatus = (status) => {
    return filterQuestListByStatus(props.quests, props.questStatus, status);
  };

  const calcRequirements = () => {
    let reqs = {};
    //requirements.QuestPoints = 0;

    let plannedQuests = getQuestsByStatus(QuestStatus.Planned);
    plannedQuests.forEach((quest) => {
      //requirements.QuestPoints = requirements.QuestPoints + quest.QuestPoints;

      quest.SkillRequirements.forEach((skillRequirement) => {
        if (
          reqs[skillRequirement.SkillName] === undefined ||
          skillRequirement.Level > reqs[skillRequirement.SkillName]
        )
          reqs[skillRequirement.SkillName] = skillRequirement.Level;
      });
    });
    setRequirements(reqs);
  };

  const getCompletedQuestPoints = () => {
    //pull quest points from players entered skills
    let playerQuestPoints = props.playerSkills["Quest Points"];

    //if user has not entered any quest points, use completed quests instead
    if (playerQuestPoints === undefined || playerQuestPoints === 0) {
      playerQuestPoints = getQuestPointsFromCompletedQuests(props.quests, props.questStatus);
    }

    return playerQuestPoints;
  };

  const getSkillStyle = (skill) => {
    let style = {
      color: "#fa541c",
      border: "1px solid #ffbb96",
      background: "#fff2e8",
      borderRadius: "2px",
      marginRight: "8px",
      display: "inline-block",
      width: "70px",
      height: "32px",
      lineHeight: "30px",
      fontSize: "16px",
      textAlign: "center",
    };

    var playerLevel =
      skill === "Quest Points" ? getCompletedQuestPoints() : props.playerSkills[skill];

    if (requirements[skill] === undefined || playerLevel >= requirements[skill]) {
      style.color = "#52c41a";
      style.border = "1px solid #b7eb8f";
      style.background = "#f6ffed";
    }
    return style;
  };

  return (
    <>
      <Divider>
        Quest Requirements
        <Tooltip title="Skills required to complete all quests in your plan.">
          <InfoCircleOutlined style={{ cursor: "pointer", marginLeft: "5px", fontSize: "14px" }} />
        </Tooltip>
      </Divider>
      <div className="quest-requirements">
        {sortSkillsByDisplayOrder(props.skills).map((skill) => (
          <div key={skill + "stat-container"} style={{ marginTop: "5px", display: "inline-block" }}>
            <span key={skill + "skill-span"} style={getSkillStyle(skill)}>
              <img
                key={skill + "skill-img"}
                style={{
                  width: "21px",
                  height: "21px",
                  marginTop: "-4px",
                  marginRight: "5px",
                }}
                alt="skill"
                src={SkillImages[skill]}
              />{" "}
              {requirements[skill] ?? "01"}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default QuestRequirements;
