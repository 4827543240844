import React from "react";
import { Tag } from "antd";
import { YoutubeOutlined, LinkOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Table } from "rsuite";
import { Divider } from "antd";
import "rsuite/dist/styles/rsuite-default.css";
import SkillImages from "./SkillImages";
import QuestStatusTag from "./QuestStatusTag";
import { QuestStatus, getChildQuestIds, filterQuestListByStatus } from "./Utils";
const { Column, HeaderCell, Cell } = Table;

const searchYoutubeGuide = (questName) => {
  window.open(
    "https://www.youtube.com/results?search_query=osrs+guide+" + questName.replace(" ", "+")
  );
};

const searchWikiGuide = (questName) => {
  window.open("https://oldschool.runescape.wiki/w/" + questName.replace(" ", "_"));
};

const QuestTable = (props) => {
  const getSkillTagStyle = (skillRequirement) => {
    let style = {
      color: "#fa541c",
      border: "1px solid #ffbb96",
      background: "#fff2e8",
      padding: "0px 3px 0px 3px",
      borderRadius: "2px",
      marginRight: "8px",
      display: "inline-block",
    };

    let playerSkillLevel = props.playerSkills[skillRequirement.SkillName];
    if (playerSkillLevel >= skillRequirement.Level) {
      style.color = "#52c41a";
      style.border = "1px solid #b7eb8f";
      style.background = "#f6ffed";
    }
    return style;
  };

  const SkillRequirements = (props) => {
    return (
      <>
        {props.requirements.map((skill) => (
          <span key={"span-" + skill.SkillName} style={getSkillTagStyle(skill)}>
            <img
              key={"img-" + skill.SkillName}
              style={{ width: "16px", height: "16px", marginTop: "-3px" }}
              alt="skill"
              src={SkillImages[skill.SkillName]}
            />{" "}
            {skill.Level}
          </span>
        ))}
      </>
    );
  };

  const InfoRow = (props) => {
    return (
      <>
        <Tag icon={<InfoCircleOutlined />} color="blue" style={{ cursor: "default" }}>
          {props.quest.Length}
        </Tag>
        <Tag icon={<InfoCircleOutlined />} color="blue" style={{ cursor: "default" }}>
          {props.quest.Members}
        </Tag>
        <Tag
          icon={<InfoCircleOutlined />}
          color="blue"
          style={{ cursor: "default", marginRight: "0px" }}
        >
          {props.quest.QuestPoints}QP
        </Tag>
        <Divider type="vertical" style={{ borderLeft: "1px solid #535353" }} />
        <Tag
          icon={<YoutubeOutlined />}
          style={{ cursor: "pointer" }}
          color="#cd201f"
          onClick={() => searchYoutubeGuide(props.quest.Name)}
        >
          Youtube
        </Tag>
        <Tag
          icon={<LinkOutlined />}
          style={{ cursor: "pointer" }}
          color="#c0a886"
          onClick={() => searchWikiGuide(props.quest.Name)}
        >
          Wiki
        </Tag>
      </>
    );
  };

  const filteredQuestList = () => {
    let quests = props.quests;

    if (props.displayMode === true) {
      quests = getTopLevelQuests();
    } else if (props.search !== undefined && props.search !== "") {
      quests = props.quests.filter((x) =>
        x.Name.toUpperCase().includes(props.search.toUpperCase())
      );
    }
    return quests;
  };

  const getTopLevelQuests = () => {
    let plannedQuests = getQuestsByStatus(QuestStatus.Planned);
    var allChildQuestIds = [];
    plannedQuests.forEach((q) => {
      allChildQuestIds = [...allChildQuestIds, ...getChildQuestIds(q)];
    });

    let topLevel = plannedQuests.filter((x) => !allChildQuestIds.includes(x.QuestId));

    return topLevel;
  };

  const getQuestsByStatus = (status) => {
    return filterQuestListByStatus(props.quests, props.questStatus, status);
  };

  return (
    <div>
      <Table
        isTree
        defaultExpandAllRows
        rowKey="key"
        height={props.height}
        data={filteredQuestList()}
        virtualized
      >
        <Column flexGrow minWidth={680}>
          <HeaderCell>Quest</HeaderCell>
          <Cell>
            {(rowData) => {
              if (rowData.InfoRow === true) {
                return <InfoRow quest={rowData} />;
              } else if (rowData.RequirementsRow === true) {
                return <SkillRequirements requirements={rowData.SkillRequirements} />;
              } else {
                return <span>{rowData.Name}</span>;
              }
            }}
          </Cell>
        </Column>

        <Column width={130} align="center" fixed={"right"}>
          <HeaderCell>Status</HeaderCell>

          <Cell>
            {(rowData) => {
              if (rowData.InfoRow !== true && rowData.RequirementsRow !== true) {
                return (
                  <QuestStatusTag
                    keyId={rowData.key}
                    quests={props.quests}
                    questId={rowData.QuestId}
                    questStatus={props.questStatus}
                    setQuestStatus={props.setQuestStatus}
                  />
                );
              } else {
                return null;
              }
            }}
          </Cell>
        </Column>
      </Table>
    </div>
  );
};

export default QuestTable;
