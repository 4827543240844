import React, { useEffect, useState } from "react";
import { message, Input, Divider, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import HighscoreSkill from "./HighscoreSkill";

let HighscoreSkillIndex = [
  "Overall",
  "Attack",
  "Defence",
  "Strength",
  "Hitpoints",
  "Ranged",
  "Prayer",
  "Magic",
  "Cooking",
  "Woodcutting",
  "Fletching",
  "Fishing",
  "Firemaking",
  "Crafting",
  "Smithing",
  "Mining",
  "Herblore",
  "Agility",
  "Thieving",
  "Slayer",
  "Farming",
  "Runecrafting",
  "Hunter",
  "Construction",
];

const Highscores = (props) => {
  const [playerName, setPlayerName] = useState("");
  const highscoresAPI = "https://osrs-proxy.azurewebsites.net/playerLookup?player=";

  useEffect(() => {
    props.skills.forEach((skill) => {
      props.playerSkills[skill.Name] = getDefaultStartingSkillLevel(skill.Name);
    });
    props.setPlayerSkills(props.playerSkills);
  }, [props.skills]);

  const fetchHighscores = () => {
    message.loading({
      content: "Searching...",
      key: "hs-notification",
      duration: 60,
      style: {
        marginLeft: "200px",
      },
    });
    axios
      .get(highscoresAPI + playerName)
      .then((x) => {
        parseHighscoreData(x.data.split("\n"));

        message.success({
          content: "Player Found!",
          style: {
            marginLeft: "200px",
          },
          key: "hs-notification",
          duration: 2,
        });
      })
      .catch(function (error) {
        message.error({
          content: "Player Not Found! Please try again, or enter stats manually.",
          style: {
            marginLeft: "200px",
          },
          key: "hs-notification",
          duration: 4,
        });
      });
  };

  const parseHighscoreData = (highscoreData) => {
    let skills = { ...props.playerSkills };

    Object.keys(props.playerSkills).forEach((skill) => {
      skills[skill] = getSkillLevelFromHighscores(skill, highscoreData);
    });
    props.setPlayerSkills(skills);
  };

  const getSkillLevelFromHighscores = (skill, highscoreData) => {
    var index = HighscoreSkillIndex.indexOf(skill);
    var level = index > -1 ? highscoreData[index].split(",")[1] : 0;
    return Number(level);
  };

  const getSkillsByDisplayOrder = () => {
    return props.skills.sort((a, b) => a.SortOrder - b.SortOrder).map((a) => a.Name);
  };

  const getDefaultStartingSkillLevel = (skill) => {
    let level = 1;
    switch (skill) {
      case "Quest Points":
        level = 0;
        break;
      case "Hosidius Favour":
        level = 0;
        break;
      case "Port Piscarilius Favour":
        level = 0;
        break;
      case "Lovakengj Favour":
        level = 0;
        break;
      default:
        level = 1;
    }
    return level;
  };

  return (
    <div key="highscores" style={{ textAlign: "center" }}>
      <Divider>
        <span style={{ cursor: "pointer" }}>Player Skills</span>
        <Tooltip title="Enter your skills, or use the highscore lookup to accurately calculate what quests you can complete.">
          <InfoCircleOutlined style={{ cursor: "pointer", marginLeft: "5px", fontSize: "14px" }} />
        </Tooltip>
      </Divider>
      <div style={{ width: "100%" }}>
        <Input.Search
          key="search"
          type="text"
          name="name"
          addonBefore="Highscores"
          placeholder="Player Name"
          onSearch={fetchHighscores}
          value={playerName}
          style={{ width: "350px" }}
          onChange={(e) => {
            setPlayerName(e.target.value);
          }}
        />
      </div>

      <div
        key="container"
        style={{
          maxWidth: "600px",
          minWidth: "350px",
          height: "150px",
          margin: "0 auto",
          display: "inline-block",
        }}
      >
        {props.apiDataHasLoaded
          ? getSkillsByDisplayOrder().map((skill) => (
              <HighscoreSkill
                key={skill}
                skill={skill}
                playerSkills={props.playerSkills}
                setPlayerSkills={props.setPlayerSkills}
                getDefaultStartingSkillLevel={getDefaultStartingSkillLevel}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Highscores;
