import Agility from "../assets/img/skills/Agility.png";
import Attack from "../assets/img/skills/Attack.png";
import Construction from "../assets/img/skills/Construction.png";
import Cooking from "../assets/img/skills/Cooking.png";
import Crafting from "../assets/img/skills/Crafting.png";
import Defence from "../assets/img/skills/Defence.png";
import Farming from "../assets/img/skills/Farming.png";
import Firemaking from "../assets/img/skills/Firemaking.png";
import Fishing from "../assets/img/skills/Fishing.png";
import Fletching from "../assets/img/skills/Fletching.png";
import Herblore from "../assets/img/skills/Herblore.png";
import Hitpoints from "../assets/img/skills/Hitpoints.png";
import Hunter from "../assets/img/skills/Hunter.png";
import Magic from "../assets/img/skills/Magic.png";
import Mining from "../assets/img/skills/Mining.png";
import Prayer from "../assets/img/skills/Prayer.png";
import Ranged from "../assets/img/skills/Ranged.png";
import Runecrafting from "../assets/img/skills/Runecrafting.png";
import Slayer from "../assets/img/skills/Slayer.png";
import Smithing from "../assets/img/skills/Smithing.png";
import Strength from "../assets/img/skills/Strength.png";
import Thieving from "../assets/img/skills/Thieving.png";
import Woodcutting from "../assets/img/skills/Woodcutting.png";

import CombatLevel from "../assets/img/skills/Combat Level.png";
import QuestPoints from "../assets/img/skills/Quest Points.png";
import HosidiusFavour from "../assets/img/skills/Hosidius Favour.png";
import PortPiscariliusFavour from "../assets/img/skills/Port Piscarilius Favour.png";
import LovakengjFavour from "../assets/img/skills/Lovakengj Favour.png";

const SkillImages = {
  Agility,
  Attack,
  Construction,
  Cooking,
  Crafting,
  Defence,
  Farming,
  Firemaking,
  Fishing,
  Fletching,
  Herblore,
  Hitpoints,
  Hunter,
  Magic,
  Mining,
  Prayer,
  Ranged,
  Runecrafting,
  Slayer,
  Smithing,
  Strength,
  Thieving,
  Woodcutting,
  "Combat Level": CombatLevel,
  "Quest Points": QuestPoints,
  "Hosidius Favour": HosidiusFavour,
  "Port Piscarilius Favour": PortPiscariliusFavour,
  "Lovakengj Favour": LovakengjFavour,
};

export default SkillImages;
