import React from "react";
import { Tag } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { QuestStatus, getChildQuestIds, getChildQuestKeys } from "./Utils";

const QuestStatusTag = (props) => {
  const handleQuestStatusClick = () => {
    let status = { ...props.questStatus };
    let childQuestIds = getChildQuestIdsByParentQuestId(props.questId);

    if (props.questStatus[props.questId] === undefined) {
      //if current status is not started, change to planned
      //set all child quests to planned
      status[props.questId] = QuestStatus.Planned;
      childQuestIds.forEach((id) => {
        if (status[id] !== QuestStatus.Completed) {
          status[id] = QuestStatus.Planned;
        }
      });
    } else if (props.questStatus[props.questId] === QuestStatus.Planned) {
      //if current status is planned, change to completed
      //set all child quests to completed
      status[props.questId] = QuestStatus.Completed;
      childQuestIds.forEach((id) => (status[id] = QuestStatus.Completed));
    } else {
      //if current status is completed, change to not started
      //change parent and child quests to not started
      delete status[props.questId];
      let parentQuestIds = removeTrailingDashes(props.keyId).split("-").map(Number);

      let keyQuestId = "-" + props.questId + "-";

      let otherEffectedQuestKeys = [];
      props.quests.forEach((q) => {
        let questChildKeys = getChildQuestKeys(q);
        let childKeysContainingQuestId = questChildKeys.filter(
          (k) => k.includes(keyQuestId) === true
        );
        otherEffectedQuestKeys = otherEffectedQuestKeys.concat(childKeysContainingQuestId);
      });

      otherEffectedQuestKeys.forEach((key) => {
        let index = key.indexOf(keyQuestId);
        if (index > 1) {
          let parentQuestIds = key.substring(2, key.indexOf(keyQuestId));

          parentQuestIds.split("-").forEach((questId) => {
            delete status[questId];
          });
        }
      });

      childQuestIds.forEach((id) => delete status[id]);
      parentQuestIds.map((id) => delete status[id]);
    }
    props.setQuestStatus(status);
  };

  const getChildQuestIdsByParentQuestId = (questId) => {
    let quest = props.quests.find((x) => x.QuestId === questId);
    return getChildQuestIds(quest);
  };

  const removeTrailingDashes = (key) => {
    return key.substr(0, key.length - 1);
  };

  let color = "default";
  let text = "Not Started";
  let icon = <MinusCircleOutlined />;

  if (props.questStatus[props.questId] === QuestStatus.Planned) {
    color = "gold";
    text = "Planned";
    icon = <PlusCircleOutlined />;
  } else if (props.questStatus[props.questId] === QuestStatus.Completed) {
    color = "green";
    text = "Completed";
    icon = <CheckCircleOutlined />;
  }

  return (
    <Tag
      key={props.keyId + "status"}
      icon={icon}
      color={color}
      style={{
        marginLeft: "10px",
        cursor: "pointer",
        float: "right",
        width: "96px",
        textAlign: "left",
      }}
      onClick={handleQuestStatusClick}
    >
      {text}
    </Tag>
  );
};

export default QuestStatusTag;
